import { Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DOCUMENT } from '@angular/common';
import { NgxSpinnerModule } from "ngx-spinner";

import { NgxMaskModule } from 'ngx-mask';
import { SocketIoModule } from 'ngx-socket-io';

import { environment } from 'environments/environment';

import { AppComponent } from './app.component';
import { InicioComponent } from './presentation/pages/inicio/inicio.component';
import { FideComponent } from './presentation/pages/fide/fide.component';
import { FooterComponent } from './presentation/shared/footer/footer.component';
import { MenuComponent } from './presentation/shared/menu/menu.component';
import { EmpresaComponent } from './presentation/pages/empresa/empresa.component';
import { ClienteComponent } from './presentation/pages/cliente/cliente.component';

import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';

import { AccountsService } from './services/accounts.service';
import { AddressService } from './services/address.service';
import { SubscriptionsService } from './services/subscriptions.service';
import { PlanosService } from './services/planos.service';
import { PointsService } from './services/points.service';
import { CancelationReasonsService } from './services/cancelation-reasons.service';

import { PlanosComponent } from './presentation/pages/planos/planos.component';
import { AssinaturaComponent } from './presentation/pages/assinatura/assinatura.component';
import { PricingComponent } from './presentation/shared/pricing/pricing.component';
import { NavBarComponent } from './presentation/shared/nav-bar/nav-bar.component';
import { EntrarComponent } from './presentation/pages/entrar/entrar.component';
import { RegistrarComponent } from './presentation/pages/registrar/registrar.component';
import { DadosEmpresaComponent } from './presentation/pages/registrar/dados-empresa/dados-empresa.component';
import { EnderecoComponent } from './presentation/pages/registrar/endereco/endereco.component';
import { InformacoesAcessoComponent } from './presentation/pages/registrar/informacoes-acesso/informacoes-acesso.component';
import { RecuperarSenhaComponent } from './presentation/pages/recuperar-senha/recuperar-senha.component';
import { PerfilComponent } from './presentation/pages/perfil/perfil.component';
import { ConfirmacaoTransacaoComponent } from './presentation/pages/confirmacao-transacao/confirmacao-transacao.component';
import { ConfirmacaoAssinaturaComponent } from './presentation/pages/confirmacao-assinatura/confirmacao-assinatura.component';
import { ConfirmacaoRecuperarSenhaComponent } from './presentation/pages/confirmacao-recuperar-senha/confirmacao-recuperar-senha.component';
import { ConfirmacaoRegistroComponent } from './presentation/pages/confirmacao-registro/confirmacao-registro.component';
import { CancelarAssinaturaComponent } from './presentation/pages/cancelar-assinatura/cancelar-assinatura.component';
import { ConfirmacaoCancelamentoAssinaturaComponent } from './presentation/pages/confirmacao-cancelamento-assinatura/confirmacao-cancelamento-assinatura.component';
import { DocApiComponent } from './presentation/pages/doc-api/doc-api.component';
import { TermoUsoComponent } from './presentation/pages/termo-uso/termo-uso.component';
import { PoliticaPrivacidadeComponent } from './presentation/pages/politica-privacidade/politica-privacidade.component';
import { SessaoSobreComponent } from './presentation/shared/sessao-sobre/sessao-sobre.component';
import { SessaoLojaComponent } from './presentation/shared/sessao-loja/sessao-loja.component';
import { SessaoIntroducaoComponent } from './presentation/shared/sessao-introducao/sessao-introducao.component';
import { SessaoBeneficioComponent } from './presentation/shared/sessao-beneficio/sessao-beneficio.component';
import { MinhaContaComponent } from './presentation/pages/minha-conta/minha-conta.component';
import { MenuMinhaContaComponent } from './presentation/shared/menu-minha-conta/menu-minha-conta.component';
import { BoasVindasComponent } from './presentation/shared/boas-vindas/boas-vindas.component';
import { MeusDadosComponent } from './presentation/pages/meus-dados/meus-dados.component';
import { MinhaAssinaturaComponent } from './presentation/pages/minha-assinatura/minha-assinatura.component';
import { TransacoesComponent } from './presentation/pages/transacoes/transacoes.component';

import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlterarFormaPagamentoComponent } from './presentation/pages/alterar-forma-pagamento/alterar-forma-pagamento.component';
import { TrabalheConoscoComponent } from './presentation/pages/trabalhe-conosco/trabalhe-conosco.component';
import { SobreComponent } from './presentation/pages/sobre/sobre.component';
import { ContatoComponent } from './presentation/pages/contato/contato.component';
import { ConfirmacaoCadastroEmpresaComponent } from './presentation/pages/confirmacao-cadastro-empresa/confirmacao-cadastro-empresa.component';
import { ComoFuncionaComponent } from './presentation/pages/como-funciona/como-funciona.component';

@NgModule({
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CommonModule,
        NgxSpinnerModule,

        NgxMaskModule.forRoot({ validation: true }),
        // SocketIoModule.forRoot({ url: environment.api.payment.socket_url }),

        AppRoutingModule
    ],
    declarations: [
        AppComponent,
        InicioComponent,
        FideComponent,
        FooterComponent,
        MenuComponent,
        EmpresaComponent,
        ClienteComponent,
        PlanosComponent,
        AssinaturaComponent,
        PricingComponent,
        NavBarComponent,
        EntrarComponent,
        RegistrarComponent,
        RecuperarSenhaComponent,
        PerfilComponent,
        ConfirmacaoTransacaoComponent,
        ConfirmacaoAssinaturaComponent,
        ConfirmacaoRecuperarSenhaComponent,
        ConfirmacaoRegistroComponent,
        CancelarAssinaturaComponent,
        ConfirmacaoCancelamentoAssinaturaComponent,
        DocApiComponent,
        TermoUsoComponent,
        PoliticaPrivacidadeComponent,
        SessaoSobreComponent,
        SessaoBeneficioComponent,
        SessaoLojaComponent,
        SessaoIntroducaoComponent,
        DadosEmpresaComponent,
        EnderecoComponent,
        InformacoesAcessoComponent,
        MinhaContaComponent,
        MenuMinhaContaComponent,
        BoasVindasComponent,
        MeusDadosComponent,
        MinhaAssinaturaComponent,
        TransacoesComponent,
        AlterarFormaPagamentoComponent,
        TrabalheConoscoComponent,
        SobreComponent,
        ContatoComponent,
        ConfirmacaoCadastroEmpresaComponent,
        ComoFuncionaComponent
    ],

    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        AddressService,
        PointsService,
        SubscriptionsService,
        AccountsService,
        PlanosService,
        CancelationReasonsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(@Inject(DOCUMENT) private document: Document) { }
}
