

export class FidelPagSeguroCardPhone {
    areaCode: string;
    number: string;
}

export class FidelPagSeguroCardAddress {
    street: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
}

export class FidelPagSeguroCardDocument {
    type: string;
    value: string;
}

export class FidelPagSeguroCardSender {
    name: string;
    email: string;
    ip: string;
    hash: string;
    phone: FidelPagSeguroCardPhone;
    address: FidelPagSeguroCardAddress;
    documents: FidelPagSeguroCardDocument[];
    constructor() {
        this.phone = new FidelPagSeguroCardPhone();
        this.address = new FidelPagSeguroCardAddress();
        this.documents = new Array<FidelPagSeguroCardDocument>();
    }
}



export class FidelPagSeguroCardHolder {
    name: string;
    birthDate: string;
    documents: FidelPagSeguroCardDocument[];
    billingAddress: FidelPagSeguroCardAddress;
    phone: FidelPagSeguroCardPhone;
    constructor() {
        this.documents = new Array<FidelPagSeguroCardDocument>();
        this.billingAddress = new FidelPagSeguroCardAddress();
        this.phone = new FidelPagSeguroCardPhone();
    }
}

export class FidelPagSeguroCardCreditCard {
    token: string;
    holder: FidelPagSeguroCardHolder;
    constructor() {
        this.holder = new FidelPagSeguroCardHolder();
    }
}

export class FidelPagSeguroCardPaymentMethod {
    type: string;
    creditCard: FidelPagSeguroCardCreditCard;
    sender: FidelPagSeguroCardSender;
    constructor() {
        this.creditCard = new FidelPagSeguroCardCreditCard();
        this.sender = new FidelPagSeguroCardSender();
    }
}

export class FidelPagSeguroCardSubscription {
    plan: string;
    reference: string;
    sender: FidelPagSeguroCardSender;
    paymentMethod: FidelPagSeguroCardPaymentMethod;
    constructor() {
        this.sender = new FidelPagSeguroCardSender();
        this.paymentMethod = new FidelPagSeguroCardPaymentMethod();
    }
}



