import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InicioComponent } from './presentation/pages/inicio/inicio.component';
import { FideComponent } from './presentation/pages/fide/fide.component';
import { ClienteComponent } from './presentation/pages/cliente/cliente.component';
import { EmpresaComponent } from './presentation/pages/empresa/empresa.component';
import { PlanosComponent } from './presentation/pages/planos/planos.component';
import { AssinaturaComponent } from './presentation/pages/assinatura/assinatura.component';
import { RegistrarComponent } from './presentation/pages/registrar/registrar.component';
import { RecuperarSenhaComponent } from './presentation/pages/recuperar-senha/recuperar-senha.component';
import { PerfilComponent } from './presentation/pages/perfil/perfil.component';
import { EntrarComponent } from './presentation/pages/entrar/entrar.component';
import { ConfirmacaoRegistroComponent } from './presentation/pages/confirmacao-registro/confirmacao-registro.component';
import { ConfirmacaoTransacaoComponent } from './presentation/pages/confirmacao-transacao/confirmacao-transacao.component';
import { ConfirmacaoAssinaturaComponent } from './presentation/pages/confirmacao-assinatura/confirmacao-assinatura.component';
import { ConfirmacaoRecuperarSenhaComponent } from './presentation/pages/confirmacao-recuperar-senha/confirmacao-recuperar-senha.component';
import { CancelarAssinaturaComponent } from './presentation/pages/cancelar-assinatura/cancelar-assinatura.component';
import { DocApiComponent } from './presentation/pages/doc-api/doc-api.component';
import { PoliticaPrivacidadeComponent } from './presentation/pages/politica-privacidade/politica-privacidade.component';
import { MinhaContaComponent } from './presentation/pages/minha-conta/minha-conta.component';
import { ConfirmacaoCancelamentoAssinaturaComponent } from './presentation/pages/confirmacao-cancelamento-assinatura/confirmacao-cancelamento-assinatura.component';
import { TransacoesComponent } from './presentation/pages/transacoes/transacoes.component';
import { MinhaAssinaturaComponent } from './presentation/pages/minha-assinatura/minha-assinatura.component';
import { MeusDadosComponent } from './presentation/pages/meus-dados/meus-dados.component';
import { TrabalheConoscoComponent } from './presentation/pages/trabalhe-conosco/trabalhe-conosco.component';
import { SobreComponent } from './presentation/pages/sobre/sobre.component';
import { ComoFuncionaComponent } from './presentation/pages/como-funciona/como-funciona.component';

import { AlterarFormaPagamentoComponent } from './presentation/pages/alterar-forma-pagamento/alterar-forma-pagamento.component';
import { ContatoComponent } from './presentation/pages/contato/contato.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmacaoCadastroEmpresaComponent } from './presentation/pages/confirmacao-cadastro-empresa/confirmacao-cadastro-empresa.component';
import { TermoUsoComponent } from './presentation/pages/termo-uso/termo-uso.component';

export const routes: Routes = [
    { path: '', redirectTo: 'inicio', pathMatch: 'full' },
    { path: 'inicio', component: InicioComponent },
    { path: 'planos', component: PlanosComponent },
    { path: 'assinatura', component: AssinaturaComponent },
    { path: 'pra-voce', component: ClienteComponent },
    { path: 'para-empresa', component: EmpresaComponent },
    { path: 'entrar', component: EntrarComponent },
    { path: 'perfil', component: PerfilComponent },
    { path: 'recuperar-senha', component: RecuperarSenhaComponent },
    { path: 'registrar', component: RegistrarComponent },
    { path: 'confirmacao-registro', component: ConfirmacaoRegistroComponent },
    // { path: 'confirmacao-transacao', component: ConfirmacaoTransacaoComponent },
    { path: 'confirmacao-assinatura', component: ConfirmacaoAssinaturaComponent },
    { path: 'confirmacao-cadastro', component: ConfirmacaoCadastroEmpresaComponent },

    { path: 'confirmacao-recuperar-senha', component: ConfirmacaoRecuperarSenhaComponent },
    { path: 'assinatura/:subscription_id/cancelar', component: CancelarAssinaturaComponent },
    { path: 'trabalhe-conosco', component: TrabalheConoscoComponent },
    { path: 'sobre', component: SobreComponent },
    { path: 'como-funciona', component: ComoFuncionaComponent },



    { path: 'confirmacao-cancelamento-assinatura', component: ConfirmacaoCancelamentoAssinaturaComponent },
    { path: 'doc', component: DocApiComponent },
    { path: 'politica-privacidade', component: PoliticaPrivacidadeComponent },
    { path: 'termo-uso', component: TermoUsoComponent },
    { path: 'contato', component: ContatoComponent },
    {
        path: 'minha-conta',
        component: MinhaContaComponent,
        children: [{
            path: '',
            redirectTo: 'minha-assinatura',
            pathMatch: 'full'
        }, {
            path: 'transacoes',
            component: TransacoesComponent
        }, {
            path: 'minha-assinatura',
            component: MinhaAssinaturaComponent
        }, {
            path: 'meus-dados',
            component: MeusDadosComponent
        }, {
            path: 'alterar-forma-pagamento',
            component: AlterarFormaPagamentoComponent
        }]
    }
]

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CommonModule,
        NgxSpinnerModule,
        BrowserModule,
        NgxMaskModule.forRoot({ validation: true }), RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
    exports: [RouterModule],

})
export class AppRoutingModule { }
