import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddressService } from '../../../../services/address.service';

@Component({
    selector: 'app-endereco',
    templateUrl: './endereco.component.html',
    styleUrls: ['./endereco.component.scss']
})
export class EnderecoComponent implements OnInit {

    @Input() form;
    valForm: FormGroup;
    constructor(
        public fb: FormBuilder,
        private service: AddressService,
        private spinner: NgxSpinnerService) { }


    ngOnInit(): void {
        this.form.addControl('step2', this.fb.group({
            cep: [null, Validators.required],
            cidade: [null, Validators.required],
            estado: [null, Validators.required],
            logradouro: [null, Validators.required],
            numero: [null, Validators.required],
            bairro: [null, Validators.required],
            latitude: [null, Validators.required],
            longitude: [null, Validators.required],

        }));
        this.valForm = this.form.controls['step2'];

    }
    buscarEndereco(): void {
        const value = this.valForm.get('cep').value;

        if (value.toString().trim().length > 7) {
            this.spinner.show();
            this.service.search(value.toString().trim()).subscribe(result => {
                this.spinner.hide();
                const response = result as any;
                if (response.erro == null && !response.erro) {
                    this.form.controls['step2'].controls['cidade'].setValue(result.localidade);
                    this.form.controls['step2'].controls['estado'].setValue(result.uf);
                    this.form.controls['step2'].controls['bairro'].setValue(result.bairro);
                    this.form.controls['step2'].controls['logradouro'].setValue(result.logradouro);
                    this.getCoodinates();
                }
            });
        }
    }
    getCoodinates(): void {
        const { cidade, estado, bairro, logradouro, cep } = this.form.controls['step2'].controls;
        const address = `${logradouro.value}, ${cidade.value}, ${estado.value}, ${cep.value}`;
        this.service.reverseGeocode(address).subscribe(result => {
            if (result.length > 0) {
                this.form.controls['step2'].controls['latitude'].setValue(result[0].lat);
                this.form.controls['step2'].controls['longitude'].setValue(result[0].lon);
            }
        });
    }
}
