<div class="wrapper wrapper-home">
    <app-nav-bar></app-nav-bar>

    <div class="wrapper-content">
        <section>
            <div class="cover-container d-flex h-100 p-3 mx-auto flex-column">
                <main role="main" class="inner cover confirmacao">
                    <h1 class="cover-heading">
                        Sua conta foi criada com sucesso!
                    </h1>
                    <p class="lead">
                        Você receberá um e-mail com um link para ativação da sua
                        conta, verifique sua caixa de entrada.
                    </p>
                    <p class="lead">
                        <a routerLink="/inicio" class="btn btn-lg btn-secondary"
                            >Voltar para a página inícial</a
                        >
                    </p>
                </main>
            </div>
        </section>
    </div>

    <app-footer></app-footer>
</div>
