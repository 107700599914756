<nav class="navbar loaded-top" id="navbar">
    <div class="container">
        <a class="navbar-brand" href="/">
            <span class="brand logo">
                <img
                    id="logo-fidel"
                    src="../../../../assets/img/logo_app.png"
                    height="80"
                />
            </span>
        </a>
        <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMenu"
            aria-controls="navbarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarMenu">
            <ul class="navbar-nav d-lg-flex flex-lg-row ml-lg-auto">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/">Início</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/pra-voce">Para Você</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/para-empresa"
                        >Para Empresas</a
                    >
                </li>-->
                <li class="nav-item">
                    <a class="nav-link" routerLink="/planos">Planos</a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        *ngIf="!session.token"
                        routerLink="/entrar"
                        >Login</a
                    >
                    <a
                        class="nav-link"
                        routerLink="/minha-conta"
                        *ngIf="session.token"
                        ><strong>Minha Conta</strong></a
                    >
                </li>
            </ul>
        </div>
    </div>
</nav>
