<footer class="hidden-xs" id="footer">
    <div class="container">
        <div class="row between-xs middle-xs social-logo">
            <div class="col-xs-4 col-md">
                <div class="box">
                    <img
                        height="80"
                        src="../../../../assets/img/logo_app.png"
                    />
                </div>
            </div>

            <div class="col-xs-8 col-md end-xs">
                <div class="box social-media">
                    <a href="https://www.facebook.com/fidelcartaofidelidade/">
                        <img
                            src="../../../../assets/img/facebook-circular-logo.png"
                            alt="Facebook"
                        />
                    </a>
                    <a href="https://twitter.com/fidelcartaofidelidade">
                        <img
                            src="../../../../assets/img/linkedin.png"
                            alt="Linkedin"
                        />
                    </a>
                    <a href="https://www.instagram.com/fidelcartaofidelidade/">
                        <img
                            src="../../../../assets/img/instagram.png"
                            alt="Instagram"
                        />
                    </a>
                    <a
                        href="https://www.youtube.com/channel/UCYeHVLGGhnm4fXpMa3I5-2g"
                    >
                        <img
                            src="../../../../assets/img/youtube.png"
                            alt="Youtube"
                        />
                    </a>
                </div>
            </div>
        </div>

        <div class="row links">
            <div class="col-xs-12 col-sm-3 col-md-3">
                <a routerLink="/">Inicio</a>
                <a routerLink="/como-funciona">Como Funciona</a>
                <a routerLink="/sobre">Sobre Nós</a>
            </div>

            <div class="col-xs-12 col-sm-3 col-md-3">
                <a routerLink="/doc">API INTEGRAÇÃO</a>
                <a href="https://painel.fidel.com.br/">PAINEL WEB</a>
            </div>

            <div class="col-xs-12 col-sm-3 col-md-3">
                <a routerLink="/politica-privacidade"
                    >Política de privacidade</a
                >
                <a routerLink="/termo-uso"
                    >Termos e Condi&ccedil;&otilde;es de Uso
                </a>
                <a routerLink="/contato">Contato</a>
                <a routerLink="/trabalhe-conosco">Trabalhe Conosco</a>
            </div>
        </div>

        <div class="row between-xs middle-xs copyright-store">
            <div class="col-xs-12 col-sm-7 col-md">
                <div class="box">
                    <p>Copyright © 2021 Fidel. Todos os direitos reservados.</p>
                    <p style="color: #fff !important">
                        Fidel Soluções Em Marketing Ltda -
                        CNPJ:36.759.653/0001-04 Com Sede na r Construtor
                        Sebastiao Soares de Souza, 40, Sala 107 1200 - Praia da
                        Costa, Vila Velha - Es, 29.101-350
                    </p>
                </div>
            </div>

            <div class="col-xs-12 col-sm-5 col-md end-md">
                <div class="box stores-links">
                    <a
                        href="https://play.google.com/store/apps/details?id=br.com.empresa.fidel"
                    >
                        <img
                            src="../../../../assets/img/google-play.png"
                            alt="Play Store"
                        />
                    </a>

                    <a
                        href="https://apps.apple.com/us/app/fidel-empresa/id1490746757?l=pt&ls=1"
                    >
                        <img
                            src="../../../../assets/img/app-store.png"
                            alt="App Store"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- COOKIES -->

<div class="cookie-bar" *ngIf="!aceito">
    <div class="container">
        <div class="row">
            <div class="col-9">
                <p class="text-termo-lgdp">
                    A Fidel utiliza cookies e tecnologias similares, como pixels
                    e tags, para oferecer a melhor experiência para o usuário.
                    Ao utilizar nossos serviços, você concorda com essa
                    utilização. Mais detalhes leia nossa
                    <a routerLink="/politica-privacidade" target="_blank"
                        >"Política de privacidade"</a
                    >
                </p>
            </div>
            <div class="col-3">
                <button
                    (click)="aceitar()"
                    class="btn btn-primary btn-lg btn-oval"
                >
                    Concordo
                </button>
            </div>
        </div>
    </div>
</div>

<!-- /COOKIES -->
