// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Rxjs
import { Observable } from 'rxjs';

// App:Environment
import { environment } from '../../environments/environment';

// App:Models

import { Cep } from '../models/cep.model';

@Injectable({
    providedIn: 'root',
})
export class AddressService {
    constructor(private http: HttpClient) { }

    search(cep: string): Observable<Cep> {
        return this.http.get<Cep>(`${environment.api.enterprise.url}/v1/address/${cep}`);
    }

    reverseGeocode(addres: string): Observable<any> {
        const item = { address: addres };
        return this.http.post<any>(`${environment.api.enterprise.url}/v1/address/reversegeocode`, item);
    }
}
