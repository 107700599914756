<div class="wrapper wrapper-home">
    <app-menu></app-menu>

    <div class="wrapper-content home">
        <section class="hero" id="hero">
            <div class="cover">
                <div class="container">
                    <div class="d-block">
                        <div class="row">
                            <div class="col-md-6">
                                <div
                                    class="d-flex"
                                    style="
                                        height: 50% !important;
                                        margin-top: 20% !important;
                                    "
                                >
                                    <div>
                                        <h1>
                                            Aumente suas vendas
                                            <br class="hidden-xs-down" />
                                            em até 30% com a Fidel
                                        </h1>
                                        <br />
                                        <p class="lead">
                                            Configure uma conta para o seu
                                            negócio e comece a usá-la
                                            <strong style="color: #ff375e"
                                                >GRATUITAMENTE</strong
                                            >
                                            <!-- <strong>Fidel</strong> é uma
                                            plataforma onde você incentiva a
                                            <br class="hidden-sm-down" />
                                            a adesão de novos clientes e
                                            <strong>fideliza</strong> os atuais.-->
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="app-store col-md-4">
                                        <a
                                            href="https://apps.apple.com/us/app/fidel-empresa/id1490746757?l=pt&ls=1"
                                            target="_blank"
                                        >
                                            <img
                                                src="../../../../assets/img/app-store.png"
                                                alt="Baixar na App Store"
                                                style="
                                                    width: 25vh;
                                                    height: auto;
                                                "
                                            />
                                        </a>
                                    </div>
                                    <div class="app-store col-md-4">
                                        <a
                                            href="https://play.google.com/store/apps/details?id=br.com.empresa.fidel"
                                            target="_blank"
                                        >
                                            <img
                                                src="../../../../assets/img/google-play.png"
                                                alt="Disponível no Google Play"
                                                style="
                                                    width: 25vh;
                                                    height: auto;
                                                "
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <img
                                    src="../../../../assets/img/woman.png"
                                    alt="COMECE A FIDELIZAR OS CLIENTES DA SUA EMPRESA AGORA MESMO, GRÁTIS!"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <app-sessao-introducao></app-sessao-introducao>

        <app-sessao-beneficio></app-sessao-beneficio>

        <app-sessao-sobre></app-sessao-sobre>

        <app-sessao-loja></app-sessao-loja>
    </div>
</div>

<app-footer></app-footer>
