<div class="row">
    <div class="col-md-12 payment">
        <h3>Historico</h3>
    </div>
</div>

<div class="card">
    <div class="card-header" style="text-align: center">
        <h3>{{ rootAssinatura.plano.nome }}</h3>
    </div>

    <div class="card-body">
        <div class="card-body detalhes-correcao">
            <h4 class="titulo-categorizacao">DETALHES DA MINHA assinatura</h4>
            <div class="row linha-detalhes">
                <div class="col-md-4">
                    <p>
                        <strong>Plano: </strong> <br />
                        {{ rootAssinatura.plano.nome }}
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b>Inicio vigência:</b><br />
                        {{
                            rootAssinatura.assinatura.permissao
                                .data_inicio_vigencia
                                | date: "dd/MM/yyyy HH:mm:ss"
                        }}
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b>Fim vigência:</b><br />
                        {{
                            rootAssinatura.assinatura.permissao
                                .data_fim_vigencia | date: "dd/MM/yyyy HH:mm:ss"
                        }}
                    </p>
                </div>
            </div>
            <div class="row linha-detalhes">
                <div class="col-md-4">
                    <p>
                        <b>Notificação via WhatsApp?</b><br />
                        <span
                            *ngIf="
                                rootAssinatura.plano.parametro
                                    .notificacao_whatsapp == true
                            "
                            class="badge badge-success badge-status"
                            data-placement="left"
                            tooltip="Sim"
                            >Sim</span
                        >
                        <span
                            *ngIf="
                                rootAssinatura.plano.parametro
                                    .notificacao_whatsapp == false
                            "
                            class="badge badge-danger badge-status"
                            data-placement="left"
                            tooltip="Não"
                            >Não</span
                        >
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b>Notificação via Telegram?</b><br />
                        <span
                            *ngIf="
                                rootAssinatura.plano.parametro
                                    .notificacao_telegram == true
                            "
                            class="badge badge-success badge-status"
                            data-placement="left"
                            tooltip="Sim"
                            >Sim</span
                        >
                        <span
                            *ngIf="
                                rootAssinatura.plano.parametro
                                    .notificacao_telegram == false
                            "
                            class="badge badge-danger badge-status"
                            data-placement="left"
                            tooltip="Não"
                            >Não</span
                        >
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b>Notificação via Push? </b><br />
                        <span
                            *ngIf="
                                rootAssinatura.plano.parametro
                                    .notificacao_push == true
                            "
                            class="badge badge-success badge-status"
                            data-placement="left"
                            tooltip="Sim"
                            >Sim</span
                        >
                        <span
                            *ngIf="
                                rootAssinatura.plano.parametro
                                    .notificacao_push == false
                            "
                            class="badge badge-danger badge-status"
                            data-placement="left"
                            tooltip="Não"
                            >Não</span
                        >
                    </p>
                </div>
            </div>
            <div class="row linha-detalhes">
                <div class="col-md-4">
                    <p>
                        <b>Quantidade de promoções (cartão): </b><br />
                        {{ rootAssinatura.plano.parametro.quantidade_cartoes }}
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b>Quantidade de notificações (mês): </b> <br />
                        {{
                            rootAssinatura.plano.parametro
                                .quantidade_notifacao_mes
                        }}
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b>Quantidade de funcionário: </b> <br />
                        {{
                            rootAssinatura.plano.parametro
                                .quantidade_funcionarios
                        }}
                    </p>
                </div>
            </div>
            <div class="row linha-detalhes">
                <div class="col-md-4">
                    <p>
                        <b>Quantidade de pontuações (mês) : </b> <br />
                        {{
                            rootAssinatura.plano.parametro.quantidade_pontuacao
                        }}
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b>Acesso ao Painel web? </b> <br />
                        <span
                            *ngIf="
                                rootAssinatura.plano.parametro.painel_web ==
                                true
                            "
                            class="badge badge-success badge-status"
                            data-placement="left"
                            tooltip="Sim"
                            >Sim</span
                        >
                        <span
                            *ngIf="
                                rootAssinatura.plano.parametro.painel_web ==
                                false
                            "
                            class="badge badge-danger badge-status"
                            data-placement="left"
                            tooltip="Não"
                            >Não</span
                        >
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b>Acesso a API Integração: </b> <br />
                        <span
                            *ngIf="
                                rootAssinatura.plano.parametro.api_integracao ==
                                true
                            "
                            class="badge badge-success badge-status"
                            data-placement="left"
                            tooltip="Sim"
                            >Sim</span
                        >
                        <span
                            *ngIf="
                                rootAssinatura.plano.parametro.api_integracao ==
                                false
                            "
                            class="badge badge-danger badge-status"
                            data-placement="left"
                            tooltip="Não"
                            >Não</span
                        >
                    </p>
                </div>
            </div>
        </div>

        <br /><br />
        <h4>Detalhes de cobrança</h4>
        <br />
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Agendamento</th>
                    <th scope="col">Data</th>
                    <th scope="col">Total</th>
                    <th scope="col">Status</th>
                    <th scope="col">Ação</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="
                        let root of rootAssinatura.assinatura.mensais;
                        let i = index
                    "
                >
                    <td>
                        {{ i + 1 }}
                    </td>
                    <td>
                        {{ root.data_agendamento | date: "dd/MM/yyyy HH:mm:ss" }}
                    </td>
                    <td>
                        {{ root.ultimo_evento | date: "dd/MM/yyyy HH:mm:ss" }}
                    </td>
                    <td>{{ root.valor | currency: "BRL":true:"1.2-2" }}</td>
                    <td>
                        <span
                            *ngIf="root.statusCode !== 5"
                            class="badge badge-danger"
                        >
                            {{ root.status }}
                        </span>
                        <span
                            *ngIf="root.statusCode === 5"
                            class="badge badge-success"
                        >
                            {{ root.status }}
                        </span>
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer">
        <button
            *ngIf="rootAssinatura.plano.free!"
            type="button"
            class="btn btn-danger"
        >
            Cancelar assinatura
        </button>
    </div>
</div>
