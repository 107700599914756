import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { CancelationReasonsService } from '@services/cancelation-reasons.service';
import { SubscriptionsService } from '@services/subscriptions.service';

@Component({
    selector: 'app-cancelar-assinatura',
    templateUrl: './cancelar-assinatura.component.html',
    styleUrls: ['./cancelar-assinatura.component.scss']
})
export class CancelarAssinaturaComponent implements OnInit {

    cancelSubscriptionForm: FormGroup;

    cancelation_reasons: any = [];

    subscription_id = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private subscriptionsService: SubscriptionsService,
        private cancelationReasonsService: CancelationReasonsService
    ) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.subscription_id = params.subscription_id;
        });

        this.cancelSubscriptionForm = this.formBuilder.group({
            cancelation_reason_id: ['', Validators.required],
            description: ['']
        });

        this.cancelationReasonsService.list().subscribe((cancelation_reasons: any) => {
            this.cancelation_reasons = cancelation_reasons;
            this.cancelSubscriptionForm.patchValue({
                cancelation_reason_id: this.cancelation_reasons[0].cancelation_reason_id
            });
        });
    }

    cancelar() {
        if (this.cancelSubscriptionForm.invalid) return;

        let cancelSubscription = this.cancelSubscriptionForm.getRawValue();

        this.subscriptionsService
            .cancel(this.subscription_id, cancelSubscription.cancelation_reason_id, cancelSubscription.description)
            .subscribe(() => this.router.navigate(['/confirmacao-cancelamento-assinatura']));
    }

    get cancelSubscriptionControls() {
        return this.cancelSubscriptionForm.controls;
    }
}
