import { Component, OnInit } from '@angular/core';
import { SubscriptionsService } from '@services/subscriptions.service';
import { RootAssinatura } from '@models/fidel.assinatura.model';

@Component({
    selector: 'app-transacoes',
    templateUrl: './transacoes.component.html',
    styleUrls: ['./transacoes.component.scss']
})
export class TransacoesComponent implements OnInit {



    rootAssinatura: RootAssinatura;


    constructor(
        private subscriptionsService: SubscriptionsService
    ) {

        this.rootAssinatura = new RootAssinatura();
    }

    ngOnInit(): void {
        this.subscriptionsService
            .searchSignature()
            .subscribe((subscriptions: any) => {
                this.rootAssinatura = subscriptions as RootAssinatura;
            });
    }

}
