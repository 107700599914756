<div class="wrapper wrapper-contact">
    <app-nav-bar></app-nav-bar>

    <div class="wrapper-content contact">
        <section style="padding-top: 80px">
            <div class="container fidel-description-contact">
                <h2>Fala com a gente</h2>
                <br /><br />
                <br />
                <p>
                    Tem interesse em parcerias, imprensa, ações com a marca, ou
                    quer fazer negócio com a gente? Envie-nos uma mensagem ;)
                </p>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-3 my-5">
                        <div class="card border-0">
                            <div class="card-body text-center">
                                <i
                                    class="fa fa-phone fa-5x mb-3"
                                    aria-hidden="true"
                                ></i>
                                <h4 class="text-uppercase mb-5">
                                    Ligue para nós
                                </h4>
                                <p>(27) 99846-9860</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3 my-5">
                        <div class="card border-0">
                            <div class="card-body text-center">
                                <i
                                    class="fa fa-whatsapp fa-5x mb-3"
                                    aria-hidden="true"
                                ></i>
                                <h4 class="text-uppercase mb-5">
                                    <a
                                        href="https://api.whatsapp.com/send?phone=+5527998469860"
                                        class="float"
                                        target="_blank"
                                    >
                                        Abrir WhatsApp
                                    </a>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3 my-5">
                        <div class="card border-0">
                            <div class="card-body text-center">
                                <i
                                    class="fa fa-globe fa-5x mb-3"
                                    aria-hidden="true"
                                ></i>
                                <h4 class="text-uppercase mb-5">E-mail</h4>
                                <p>contato@fidel.com.br</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>
