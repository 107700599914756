<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <form
                [formGroup]="valForm"
                class="form-validate form-horizontal"
                novalidate=""
            >
                <div class="card card-default">
                    <div class="card-body text-left">
                        <strong>
                            Para realizar o seu cadastro precisamos saber de de
                            algumas informações.</strong
                        ><br /><br /><br />
                        <div class="form-group row text-center">
                            <div class="col-lg-6">
                                <label>Nome empresa</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="nome"
                                    autocomplete="off"
                                    [formControl]="valForm.controls['nome']"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['nome'].hasError(
                                            'required'
                                        ) &&
                                        (valForm.controls['nome'].dirty ||
                                            valForm.controls['nome'].touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                            <div class="col-lg-6">
                                <label>CNPJ</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="cnpj"
                                    mask="00.000.000/0000-00"
                                    autocomplete="off"
                                    [formControl]="valForm.controls['cnpj']"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['cnpj'].hasError(
                                            'required'
                                        ) &&
                                        (valForm.controls['cnpj'].dirty ||
                                            valForm.controls['cnpj'].touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                        </div>
                        <div class="form-group row text-center">
                            <div class="col-lg-6">
                                <label>Telefone</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="celular"
                                    autocomplete="off"
                                    mask="(00) 00000 0000"
                                    [formControl]="valForm.controls['celular']"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['celular'].hasError(
                                            'required'
                                        ) &&
                                        (valForm.controls['celular'].dirty ||
                                            valForm.controls['celular'].touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                            <div class="col-lg-6">
                                <label>Nome do responsável</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="responsavel"
                                    autocomplete="off"
                                    [formControl]="
                                        valForm.controls['responsavel']
                                    "
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls[
                                            'responsavel'
                                        ].hasError('required') &&
                                        (valForm.controls['responsavel']
                                            .dirty ||
                                            valForm.controls['responsavel']
                                                .touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="segmento">Segmento</label>
                            <select
                                name="segmento"
                                id="segmento"
                                class="form-control"
                                formControlName="segmento"
                                autocomplete="off"
                                [formControl]="valForm.controls['segmento']"
                            >
                                <option
                                    *ngFor="let method of segmentos"
                                    [value]="method.id_segmento"
                                >
                                    {{ method.nome }}
                                </option>
                            </select>
                            <span
                                class="text-danger"
                                *ngIf="
                                    valForm.controls['segmento'].hasError(
                                        'required'
                                    ) &&
                                    (valForm.controls['segmento'].dirty ||
                                        valForm.controls['segmento'].touched)
                                "
                                >Este campo é obrigatório</span
                            >
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
