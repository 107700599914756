import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { LocalstorageService } from '.././helpers/local-storage';

import { Plan } from '../models/plan.model';


import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PlanosService {

    constructor(
        private http: HttpClient
        // private localStorage: LocalstorageService
    ) { }

    single(planId: number) {
        return this.http.get<any>(`${environment.api.payment.url}/v1/plans/${planId}`);
    }

    search(): Observable<any> {
        // const session = JSON.parse(localStorage.getItem('@fidel-session')) || {};
        return this.http.get<any>(`${environment.api.payment.url}/v1/plans`);
        //this.socket.emit('search-plans', { token: session.token });
    }
}
