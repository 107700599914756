<div class="wrapper wrapper-contact">
    <app-nav-bar></app-nav-bar>

    <div class="wrapper-content contact">
        <section style="padding-top: 80px">
            <div class="container fidel-description">
                <h2>Sobre nós :)</h2>
                <br /><br />
                <p>
                    Hoje a cada estabelecimento que frequentamos, como
                    restaurantes, bares ou estéticas, é entregue um cartão de
                    fidelidade de papel diferente, o habitual é que os clientes
                    percam os cartões e os estabelecimentos não tem controle
                    para quem e quantos cartões foram entregues, além do fato
                    desses cartões serem feitos de papel o que envolve toda uma
                    questão ambiental.
                </p>
                <p>
                    O Fidel vem com objetivo de resolver esses problemas
                    existentes no mercado. Segundo uma pesquisa feito pelo
                    portal Terra 92% dos brasileiros têm interesse em programas
                    de fidelidade e tem sido uma tendência para os próximos anos
                    apesar de não ser uma novidade.
                </p>
                <p>
                    O Fidel possui uma escalabilidade capaz de atender quantas
                    empresas forem alcançadas, dentro ou fora do país, por ser
                    uma plataforma digital não terá um alto custo para ampliar o
                    negócio.
                </p>
                <br /><br />
                <div class="row d-flex justify-content-center">
                    <div class="col-md-6">
                        <div class="card-available">
                            <i class="icon-motorcycle"></i>
                            <h3>+ 500 mil pontuações</h3>
                            <p>
                                Em pouco tempo de vida a Fidel reúne uma base de
                                mais de 500 mil pontuações.
                            </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card-available">
                            <h3>+ 30 mil pontuações/mês</h3>
                            <p>Temos uma media de 30 mil pontuação por mês.</p>
                        </div>
                    </div>
                </div>
                <br />
                <div class="row d-flex justify-content-center">
                    <div class="col-md-6">
                        <div class="card-available">
                            <i class="icon-mini-van"></i>
                            <h3>Em todos os estados brasileiros</h3>
                            <p>
                                Em todo os estados brasileiros existe algum
                                estabelecimento que utiliza a Fidel para
                                fidelizar seus clientes.
                            </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card-available">
                            <i class="icon-truck"></i>
                            <h3>+ 85 mil usuários</h3>
                            <p>
                                Desde o nascimento da Fidel em 2019, reunimos
                                mais de 85 mil usuários interessados em ser
                                fidelizados.
                            </p>
                        </div>
                    </div>
                </div>
                <br /><br />
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>
