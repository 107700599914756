import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from '@services/accounts.service';

@Component({
    selector: 'app-meus-dados',
    templateUrl: './meus-dados.component.html',
    styleUrls: ['./meus-dados.component.scss']
})
export class MeusDadosComponent implements OnInit {
    meusDadosForm: FormGroup;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private accountsService: AccountsService
    ) { }

    ngOnInit(): void {
        this.meusDadosForm = this.formBuilder.group({
            nome: [null, Validators.required],
            email: [null, Validators.required],
            celular: [null, Validators.required],
        });

        this.accountsService
            .getInformation()
            .subscribe((information: any) => {
                this.meusDadosForm.patchValue(information.usuario);
            });
    }

    get form() { return this.meusDadosForm.controls; }

    onSubmit() {
        if (this.meusDadosForm.invalid) {
            return;
        }

        alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.meusDadosForm.value, null, 4));
    }

}
