import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class AssinaturasService {

    constructor(private _http: HttpClient) { }
    chagenPaymentMethod(record: any) {
        return this._http.post(`${environment.api.payment.url}/v1/signature/chagenpaymentmethod`, record).toPromise();
    }
    paymentRetry() {
        return this._http.post(`${environment.api.payment.url}/v1/subscription/paymentRetry`, null).toPromise();
    }
    subscribe(assinatura: any) {
        return this._http.post(`${environment.api.payment.url}/v1/subscription`, assinatura).toPromise();
    }
    cancelSubscribe(): Observable<any> {
        return this._http.post(`${environment.api.payment.url}/v1/signature/cancel`, null);
    }

    accountInformation(): Observable<any> {
        return this._http.get<any>(`${environment.api.enterprise.url}v1/company/information`);
    }
    getSession(): Observable<any> {
        return this._http.get<any>(`${environment.api.payment.url}/v1/subscription/session`);
    }
    searchSignature() {
        return this._http.get(`${environment.api.payment.url}/v1/signature`);
    }
}
