<div class="wrapper wrapper-enterprise">
    <app-menu></app-menu>

    <div class="wrapper-content home">
        <section class="hero" id="hero">
            <div class="cover">
                <div class="container">
                    <div class="d-block">
                        <div class="row">
                            <div class="col-md-6">
                                <div
                                    class="d-flex"
                                    style="
                                        height: 50% !important;
                                        margin-top: 20% !important;
                                    "
                                >
                                    <div>
                                        <h1>
                                            Aumente suas vendas
                                            <br class="hidden-xs-down" />
                                            em até 30% com o Fidel
                                        </h1>
                                        <br />
                                        <p class="lead">
                                            O <strong>Fidel</strong> é uma
                                            plataforma onde você incentiva a
                                            <br class="hidden-sm-down" />
                                            a adesão de novos clientes e
                                            <strong>fideliza</strong> os atuais.
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="app-store col-md-4">
                                        <a
                                            href="https://apps.apple.com/us/app/fidel-empresa/id1490746757?l=pt&ls=1"
                                            target="_blank"
                                        >
                                            <img
                                                src="../../../../assets/img/app-store.png"
                                                alt="Baixar na App Store"
                                                style="
                                                    width: 25vh;
                                                    height: auto;
                                                "
                                            />
                                        </a>
                                    </div>
                                    <div class="app-store col-md-4">
                                        <a
                                            href="https://play.google.com/store/apps/details?id=br.com.empresa.fidel"
                                            target="_blank"
                                        >
                                            <img
                                                src="../../../../assets/img/google-play.png"
                                                alt="Disponível no Google Play"
                                                style="
                                                    width: 25vh;
                                                    height: auto;
                                                "
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <img
                                    src="../../../../assets/img/1a.png"
                                    alt="COMECE A FIDELIZAR OS CLIENTES DA SUA EMPRESA AGORA MESMO, GRÁTIS!"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="block-content for-whom">
            <div class="container">
                <header>
                    <h2>Para quem?</h2>
                    <h3>
                        Não importa se é para você ou seu negócio:
                        <br class="hidden-sm-down" />
                        fretes de forma simples e direta.
                    </h3>
                </header>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card-pages for-you">
                            <i class="icon-you">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                            </i>
                            <h3>Para Você</h3>
                            <p>
                                Comodidade! Com apenas alguns cliques você faz
                                seu pedido da palma da mão.
                            </p>
                            <a class="btn" href="para-voce.html">
                                Saiba Mais
                            </a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-pages for-enterprise">
                            <i class="icon-enterprise">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                            <h3>Para Empresas</h3>
                            <p>
                                Solução logística para sua empresa. Entregas
                                eficientes por menos do que imagina.
                            </p>
                            <a class="btn" href="para-empresas.html">
                                Saiba Mais
                            </a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-pages for-partner">
                            <i class="icon-partner">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                            <h3>Para Fretistas</h3>
                            <p>
                                Aumente sua renda e sua liberdade. Você a frente
                                dos seus negócios.
                            </p>
                            <a class="btn" href="para-parceiros.html">
                                Saiba Mais
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="block-content advantages">
            <div class="container">
                <header>
                    <h2>Vantagens</h2>
                    <h3>
                        Monte seu frete online e acompanhe
                        <br class="hidden-sm-down" />
                        tudo em tempo real
                    </h3>
                    <p>
                        Transporte o que você precisa, do jeito que você
                        precisa.
                    </p>
                </header>
                <div class="row">
                    <div class="col-sm-6 col-md-4 order-md-1 order-2">
                        <div class="topic">
                            <h3>Transparência</h3>
                            <p>
                                Transparência é essencial, por isso buscamos
                                compartilhar o máximo de informações em tempo
                                real com você.
                            </p>
                        </div>
                        <div class="topic fix-margin">
                            <h3>Comodidade</h3>
                            <p>
                                Alguns cliques e na palma da mão. Contamos com
                                inúmeros recursos para facilitar o seu dia a dia
                                com a comodidade que você quer.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 order-md-2 order-1">
                        <img
                            src="assets/imgs/beebee-advantages-phone.png"
                            alt="Celular mostrando o aplicativo do BeeBee"
                        />
                    </div>
                    <div class="col-sm-6 col-md-4 order-md-3 order-3">
                        <div class="topic">
                            <h3>Eficiência</h3>
                            <p>
                                Nossa missão é atender sua demanda da melhor
                                maneira e no menor tempo possível. Notificamos
                                seu destinatário por SMS quando o fretista está
                                a caminho. Sabemos o que importa pra você. ;)
                            </p>
                        </div>
                        <div class="topic fix-margin">
                            <h3>Economia</h3>
                            <p>
                                Sem custo de frota, funcionário ou ociosidade.
                                Você monta seu pedido a sua maneira, confere
                                quanto vai custar e paga somente pelo que usar.
                                Sim, reduzimos seu custo.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="block-content social-proof" hidden="">
            <div class="container">
                <header>
                    <h2>Veja alguns relatos de clientes BeeBee</h2>
                    <h3>
                        Nossa proposta é usar a tecnologia de ponta para
                        modernizar,
                        <br class="hidden-md-down" />
                        elevar a performance, e reduzir os gastos com suas
                        entregas.
                    </h3>
                </header>
                <div class="row">
                    <div class="col-12">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <!-- Slide 1 -->
                                <div class="swiper-slide">
                                    <div class="card">
                                        <div class="card-body">
                                            <p class="card-text">
                                                "Preciso de um veículo exclusivo
                                                por determinado período de tempo
                                                e por isso a BeeBee me atende
                                                muito bem. Além disso, economizo
                                                com encargos trabalhistas!"
                                            </p>
                                        </div>
                                        <div class="card-footer">
                                            <img
                                                src="assets/imgs/testimonial-1.jpg"
                                                alt="Foto de um homem"
                                            />
                                            <div class="card-info">
                                                <p>Rodrigo Maranth</p>
                                                <p>Rede FARMES</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Slide 2 -->
                                <div class="swiper-slide">
                                    <div class="card">
                                        <div class="card-body">
                                            <p class="card-text">
                                                "Ter frota própria gera muitos
                                                custos. Com a solução da BeeBee
                                                estamos economizando em
                                                manutenções, combustíveis,
                                                seguros, dentre outros gastos."
                                            </p>
                                        </div>
                                        <div class="card-footer">
                                            <img
                                                src="assets/imgs/testimonial-2.jpg"
                                                alt="Foto de um homem"
                                            />
                                            <div class="card-info">
                                                <p>Lucas F. e Freire</p>
                                                <p>Millenium Comercial</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Slide 3 -->
                                <div class="swiper-slide">
                                    <div class="card">
                                        <div class="card-body">
                                            <p class="card-text">
                                                "Na plataforma BeeBee tenho
                                                total controle de meus fretes.
                                                Vejo o histórico das entregas de
                                                forma bem detalhada e amigável."
                                            </p>
                                        </div>
                                        <div class="card-footer">
                                            <img
                                                src="assets/imgs/testimonial-3.jpg"
                                                alt="Foto de uma mulher"
                                            />
                                            <div class="card-info">
                                                <p>Maria Josefina</p>
                                                <p>Evino</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- If we need pagination -->
                            <div class="swiper-pagination"></div>

                            <!-- If we need navigation buttons -->
                            <div class="swiper-button-prev">
                                <i
                                    class="icon-left-arrow"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div class="swiper-button-next">
                                <i
                                    class="icon-right-arrow"
                                    aria-hidden="true"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section
            class="block-content cta-home"
            style="background-color: #f2fcfe"
        >
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <header>
                            <h3>Sua necessidade é a nossa motivação!</h3>
                            <p>
                                Junto com você, queremos revolucionar o
                                transporte de mercadorias no Brasil.
                            </p>
                            <a
                                class="btn"
                                href="https://app.beebee.com.br/cadastro"
                            >
                                Cadastrar
                            </a>
                        </header>
                    </div>
                    <div class="col-md-6">
                        <img
                            src="assets/imgs/beebee-van.png"
                            alt="Van do BeeBee"
                        />
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>
