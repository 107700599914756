import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountsService } from '@services/accounts.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-menu-minha-conta',
    templateUrl: './menu-minha-conta.component.html',
    styleUrls: ['./menu-minha-conta.component.scss']
})
export class MenuMinhaContaComponent implements OnInit {

    constructor(
        private router: Router,
        private accountsService: AccountsService
    ) { }

    ngOnInit(): void {
    }

    signOut() {
        this.accountsService.signOut();
        this.router.navigate(['/inicio']);
    }
}
