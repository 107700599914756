import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-minha-conta',
    templateUrl: './minha-conta.component.html',
    styleUrls: ['./minha-conta.component.scss']
})
export class MinhaContaComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {

    }

}
