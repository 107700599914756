import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Company } from '@models/company.model';
import { RegistrarService } from '../../../services/registrar.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';


@Component({
    selector: 'app-registrar',
    templateUrl: './registrar.component.html',
    styleUrls: ['./registrar.component.scss']
})
export class RegistrarComponent implements OnInit {

    step = 1;
    valForm: FormGroup;
    constructor(
        private formBuilder: FormBuilder,
        private service: RegistrarService,
        private spinner: NgxSpinnerService,
        private router: Router) {
        this.valForm = this.formBuilder.group({

        });
    }
    ngOnInit(): void {
    }
    next(): void {
        if (this.step === 1) {
            if (this.checkStep('step1')) {
                this.step++;
            }
        } else if (this.step === 2) {
            if (this.checkStep('step2')) {
                this.step++;
            }
        } else if (this.step === 3) {
            if (this.checkStep('step3')) {
                this.step++;
            }
        }
    }
    back(): void {
        if (this.step !== 1) { this.step--; }

    }

    salvar(): void {
        const form = this.valForm as FormGroup;
        const { nome, celular, cnpj, responsavel, segmento } = (this.valForm.controls['step1'] as FormGroup).controls;
        const { bairro, cep, estado, latitude, cidade, logradouro, longitude, numero } = (this.valForm.controls['step2'] as FormGroup).controls;
        const { email, senha, codigoIndicacao } = (this.valForm.controls['step3'] as FormGroup).controls;
        const store = new Company();
        store.nome = nome.value;
        store.telefone = celular.value;
        store.cnpj = cnpj.value;
        store.nomeReponsavel = responsavel.value;
        store.bairro = bairro.value;
        store.cep = cep.value;
        store.cidade = cidade.value;
        store.uf = estado.value;
        store.latitude = latitude.value;
        store.endereco = logradouro.value;
        store.longitude = longitude.value;
        store.numero = numero.value;
        store.email = email.value;
        store.senha = senha.value;
        store.id_segmento = segmento.value;
        store.referencia = codigoIndicacao.value;
        this.spinner.show();
        this.service.cadastrar(store).subscribe(
            data => {
                this.spinner.hide();

                if (data.success) {
                    this.router.navigate(['/confirmacao-cadastro']);

                }
            },
            err => {
                this.spinner.hide();
            }
        );

    }
    checkStep(step): boolean {
        const form = this.valForm.controls[step] as FormGroup;

        for (let c in form.controls) {
            form.controls[c].markAsTouched();
        }
        return form.valid;
    }
}
