import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sessao-beneficio',
  templateUrl: './sessao-beneficio.component.html',
  styleUrls: ['./sessao-beneficio.component.scss']
})
export class SessaoBeneficioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
