import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { Plan } from '../models/plan.model';

@Injectable({
    providedIn: 'root'
})
export class PointsService {

    constructor(private http: HttpClient) { }

    single(plan_id: number) {
        return this.http.get<Plan>(`${environment.api.points.url}/v1/plan/${plan_id}`);
    }

    search() {
        return this.http.get<Array<Plan>>(`${environment.api.points.url}/v1/points`);
    }
}
