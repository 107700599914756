import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Session } from '@models/session.model';

import { AccountsService } from '@services/accounts.service';
import { PlanosService } from '@services/planos.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-planos',
    templateUrl: './planos.component.html',
    styleUrls: ['./planos.component.scss']
})
export class PlanosComponent implements OnInit {

    plans: any;

    session: Session;

    constructor(
        private router: Router,
        private accountsService: AccountsService,
        private planosService: PlanosService,
        private spinner: NgxSpinnerService
    ) {
        this.session = new Session();
    }

    ngOnInit(): void {
        this.spinner.show();
        this.planosService.search().subscribe((plans) => {
            this.spinner.hide();
            this.plans = plans;
        });

        /* this.planosService.search();

         this.accountsService.sessionSubject.subscribe((session: Session) => {
             if (session)
                 this.session = session;
             else
                 this.session = new Session();
         });*/

        this.accountsService.sessionSubject.subscribe((session: Session) => {
            if (session)
                this.session = session;
            else
                this.session = new Session();
        });
    }

    assinar(plan_id: number) {
        if (this.session.token)
            this.router.navigate(['/assinatura'], { queryParams: { plan_id } });
        else
            this.router.navigate(['/entrar']);
    }

    cancelar(subscription_id: number) {
        this.router.navigate(['/assinatura', subscription_id, 'cancelar']);
    }

    possuiAssinatura(plan: any) {
        return !this.naoPossuiAssinatura(plan);
    }

    naoPossuiAssinatura(plan: any) {
        return this.naoEstaAutenticado() || !plan.subscriptions.length;
    }

    possuiAlgumaAssinatura() {
        return !this.naoPossuiAlgumaAssinatura();
    }

    naoPossuiAlgumaAssinatura() {
        let naoPossuiAssinatura = true;

        this.plans && this.plans.forEach((plan: any) => {
            if (plan.subscriptions.length && plan.subscriptions[0].id) {
                naoPossuiAssinatura = false;
            }
        });

        return this.naoEstaAutenticado() || naoPossuiAssinatura;
    }

    estaAutenticado() {
        return this.session.token;
    }

    naoEstaAutenticado() {
        return !this.estaAutenticado();
    }
}
