import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrarService } from '@services/registrar.service';

@Component({
    selector: 'app-dados-empresa',
    templateUrl: './dados-empresa.component.html',
    styleUrls: ['./dados-empresa.component.scss']
})
export class DadosEmpresaComponent implements OnInit {
    @Input() form;
    valForm: FormGroup;
    segmentos = [];
    constructor(public fb: FormBuilder,
        private service: RegistrarService) { }

    ngOnInit(): void {
        this.form.addControl('step1', this.fb.group({
            nome: [null, Validators.required],
            celular: [null, Validators.required],
            cnpj: [null, Validators.required],
            responsavel: [null, Validators.required],
            segmento: [null, Validators.required],

        }));
        this.valForm = this.form.controls['step1'];
        /*   this.form.valueChanges.subscribe(() => {

           });*/

        this.loadSegmentos();
    }

    loadSegmentos() {
        this.service.segmentos()
            .subscribe(result => {
                this.segmentos = result;
            });
    }
}
