import { Router } from '@angular/router';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { AccountsService } from '@services/accounts.service';
import { Session } from '@models/session.model';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {
    accountForm: FormGroup;

    session: Session;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private accountsService: AccountsService
    ) { }

    ngOnInit() {
        this.accountForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.accountsService
            .sessionSubject
            .subscribe((session: Session) => {
                if (session)
                    this.session = session;
                else
                    this.session = new Session();
            });
    }

    sair() {
        this.accountsService.signOut();
        this.router.navigate(['/inicio']);
    }

    get accountControls() {
        return this.accountForm.controls;
    }
}
