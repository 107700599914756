import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Company } from '../models/company.model';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RegistrarService {

    constructor(private http: HttpClient) { }
    cadastrar(record: Company): Observable<any> {
        return this.http
            .post(`${environment.api.enterprise.url}v1/company`, record);
        //pipe(catchError(this.handleError));
    }
    segmentos(): Observable<any> {
        return this.http
            .get(`${environment.api.enterprise.url}/v1/company/segments`, {});

    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            console.error(error);
            console.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }
}
