import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionsService {

    onSubscriptionChanged = new BehaviorSubject<any>(null);

    constructor(private http: HttpClient) { }

    subscribe(subscription: any): Promise<void> {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${environment.api.payment.url}/v1/subscription`, subscription)
                .subscribe((response: any) => {
                    this.onSubscriptionChanged.next(response);
                    resolve();
                });
        });
    }

    cancel(subscription_id: number, cancelation_reason_id: number, description: string) {
        return this.http.post(`${environment.api.payment.url}/v1/subscription/${subscription_id}/cancel`, {
            cancelation_reason_id,
            description
        });
    }

    searchSignature() {
        return this.http.get(`${environment.api.payment.url}/v1/signature`);
    }

    current() {
        return this.http.get(`${environment.api.payment.url}/v1/subscription/current`);
    }
}
