import { Component, OnInit, Input } from '@angular/core';

import { Plan } from '@models/plan.model';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
    @Input() plan: Plan;
    constructor() { }

    ngOnInit(): void {
    }

}
