<section class="fidel-description section-intro">
    <div class="container">
        <header>
            <h3>Crie seu cartão de fidelidade digital em minutos</h3>
            <p>
                Conhecer seus clientes ajuda a ajustar suas ofertas de negócios
                de acordo com o padrão de visitas do cliente, o que por sua vez
                aumenta os clientes regulares. Quanto mais clientes regulares
                você conseguir, mais receita poderá gerar para o seu negócio.
            </p>
        </header>
        <!-- <div class="row">
            <div class="col-md-4">
                <div class="card-pages for-you">
                    <i class="icon-you">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                    </i>
                    <h3>Para Você</h3>
                    <p>
                        Comodidade! Com apenas alguns cliques você faz seu
                        pedido da palma da mão.
                    </p>
                    <a class="btn" href="para-voce.html"> Saiba Mais </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card-pages for-enterprise">
                    <i class="icon-enterprise">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>
                    <h3>Para Empresas</h3>
                    <p>
                        Solução logística para sua empresa. Entregas eficientes
                        por menos do que imagina.
                    </p>
                    <a class="btn" href="para-empresas.html"> Saiba Mais </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card-pages for-partner">
                    <i class="icon-partner">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>
                    <h3>Para Fretistas</h3>
                    <p>
                        Aumente sua renda e sua liberdade. Você a frente dos
                        seus negócios.
                    </p>
                    <a class="btn" href="para-parceiros.html"> Saiba Mais </a>
                </div>
            </div>
        </div>-->
    </div>
</section>
