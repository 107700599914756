<div class="wrapper wrapper-contact">
    <app-nav-bar></app-nav-bar>

    <div class="container">
        <app-boas-vindas></app-boas-vindas>
        <div class="row">
            <div class="col-md-4 order-md-1 mb-4">
                <app-menu-minha-conta></app-menu-minha-conta>
            </div>
            <div class="col-md-8 order-md-1 myaccount">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
