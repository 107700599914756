<section class="block-content cta-home" style="background-color: #4f008d">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <header>
                    <h3>POSSUI UM ESTABELECIMENTO?</h3>
                    <p>
                        Divulgue seu negócio, conquiste novos clientes, fidelize
                        os atuais e aumente as vendas do seu estabelecimento com
                        a Fidel.
                    </p>
                    <a class="btn" routerLink="/registrar">
                        Inscreva-se agora!
                    </a>
                </header>
            </div>
            <div class="col-md-6">
                <img src="../../../../assets/img/store.png" alt="Loja Fidel" />
            </div>
        </div>
    </div>
</section>
