<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <form
                [formGroup]="valForm"
                class="form-validate form-horizontal"
                novalidate=""
            >
                <div class="card card-default">
                    <div class="card-body">
                        <p>
                            <strong
                                >Precisamos saber do endereço da sua
                                loja!</strong
                            >
                        </p>
                        <p>
                            Isso é necessário para podermos listar seu
                            estabelecimento em nosso aplicativo e o seu e outros
                            clientes veja a sua localização e possa alcançar
                            mais visibilidade.
                        </p>
                        <br /><br /><br />
                        <div class="form-group row text-center">
                            <div class="col-md-4 form-group">
                                <label>CEP</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    mask="00000-000"
                                    formControlName="cep"
                                    autocomplete="off"
                                    (change)="buscarEndereco()"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['cep'].hasError(
                                            'required'
                                        ) &&
                                        (valForm.controls['cep'].dirty ||
                                            valForm.controls['cep'].touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                            <div class="col-lg-4 form-group">
                                <label>Cidade</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="cidade"
                                    autocomplete="off"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['cidade'].hasError(
                                            'required'
                                        ) &&
                                        (valForm.controls['cidade'].dirty ||
                                            valForm.controls['cidade'].touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                            <div class="col-lg-4 form-group">
                                <label>Estado</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="estado"
                                    autocomplete="off"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['estado'].hasError(
                                            'required'
                                        ) &&
                                        (valForm.controls['estado'].dirty ||
                                            valForm.controls['estado'].touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                        </div>
                        <div class="form-group row text-center">
                            <div class="col-lg-4 form-group">
                                <label>Bairro</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="bairro"
                                    autocomplete="off"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['bairro'].hasError(
                                            'required'
                                        ) &&
                                        (valForm.controls['bairro'].dirty ||
                                            valForm.controls['bairro'].touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                            <div class="col-lg-6 form-group">
                                <label>Logradouro</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="logradouro"
                                    autocomplete="off"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['logradouro'].hasError(
                                            'required'
                                        ) &&
                                        (valForm.controls['logradouro'].dirty ||
                                            valForm.controls['logradouro']
                                                .touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                            <div class="col-md-2 form-group">
                                <label>Número</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="numero"
                                    autocomplete="off"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['numero'].hasError(
                                            'required'
                                        ) &&
                                        (valForm.controls['numero'].dirty ||
                                            valForm.controls['numero'].touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                        </div>
                        <div class="form-group row text-center">
                            <div class="col-md-12">
                                <div
                                    class="alert alert-danger"
                                    *ngIf="
                                        form.controls.step2.controls[
                                            'latitude'
                                        ].hasError('required') &&
                                        (form.controls.step2.controls[
                                            'latitude'
                                        ].dirty ||
                                            form.controls.step2.controls[
                                                'latitude'
                                            ].touched) &&
                                        form.controls.step2.controls[
                                            'longitude'
                                        ].hasError('required') &&
                                        (form.controls.step2.controls[
                                            'longitude'
                                        ].dirty ||
                                            form.controls.step2.controls[
                                                'longitude'
                                            ].touched)
                                    "
                                    role="alert"
                                >
                                    Não foi possível obter as coordenadas do seu
                                    estabelecimento.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
