<div class="wrapper wrapper-contact">
    <app-nav-bar></app-nav-bar>

    <div class="wrapper-content contact">
        <section style="padding-top: 80px">
            <div class="container">
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: center;
                        text-indent: 35.45pt;
                        font-size: 19px;
                        font-family: Candara, sans-serif;
                        font-weight: bold;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    Termos e Condi&ccedil;&otilde;es de Uso &ndash; Fidel
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        font-size: 19px;
                        font-family: Candara, sans-serif;
                        font-weight: bold;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    <span style="font-weight: normal">&nbsp;</span>
                </p>
                <h1
                    style="
                        margin: 0cm 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;&nbsp;Quem somos
                </h1>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    A Fidel &eacute; uma empresa que disponibiliza e gerencia
                    meios de fideliza&ccedil;&atilde;o (ex: cart&otilde;es de
                    fidelidade digital), premia&ccedil;&otilde;es, Cashback,
                    marketing dentre outros servi&ccedil;os a outras empresas
                    (B2B). Disponibilizamos uma forma mais f&aacute;cil e
                    acess&iacute;vel para que neg&oacute;cios bonifiquem e
                    fidelizem seus consumidores, criem
                    promo&ccedil;&otilde;es,&nbsp;&nbsp;tenham visibilidade e
                    at&eacute; notifiquem seus consumidores de maneira simples
                    atrav&eacute;s do aplicativo da Fidel. Temos por objetivo
                    facilitar o dia a dia do empreendedor ao disponibilizar uma
                    ferramenta de cadastro r&aacute;pido de clientes,
                    al&eacute;m de ajudar a aumentar a recorr&ecirc;ncia dos
                    seus clientes atrav&eacute;s da fideliza&ccedil;&atilde;o
                    dos mesmos.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <h1
                    style="
                        margin: 0cm 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;Objeto
                </h1>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    <span style="color: black"
                        >Pelos presentes Termos e Condi&ccedil;&otilde;es de Uso
                        ficam estabelecidos direitos e obriga&ccedil;&otilde;es
                        dos Usu&aacute;rios habilitados para o acesso e
                        utiliza&ccedil;&atilde;o da plataforma dispon&iacute;vel
                        no s&iacute;tio eletr&ocirc;nico&nbsp;</span
                    >(INSERIR SITE) e aplicativo<span style="color: black"
                        >.&nbsp;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <h1
                    style="
                        margin: 0cm 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;Defini&ccedil;&otilde;es importantes:
                </h1>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 71.45pt;
                        text-align: justify;
                        text-indent: 0cm;
                        line-height: normal;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <strong
                        ><span style="color: black"
                            >(a)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;</span
                        ></strong
                    ><span style="color: black"
                        >&quot;<strong>Contratada</strong>&quot;:</span
                    ><span style="color: red">&nbsp;</span
                    ><span style="color: black"
                        >FIDEL SOLU&Ccedil;&Otilde;ES EM MARKETING LTDA,
                        sociedade empres&aacute;ria limitada, inscrita no CNPJ
                        sob n&ordm; 36.759.653/0001-04 com sede no
                        munic&iacute;pio de Vila Velha do Estado do
                        Esp&iacute;rito Santo, na Rua Construtor
                        Sebasti&atilde;o Soares De Souza, n&ordm;40, SALA 107,
                        PRAIA DA COSTA, CEP: 29.101-350;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <strong
                        ><span style="color: black"
                            >(b)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;</span
                        ></strong
                    ><span style="color: black"
                        >&quot;<strong>Contratante</strong>&quot;:&nbsp;&nbsp;pessoa
                        natural ou jur&iacute;dica, tamb&eacute;m chamada de
                        &ldquo;Estabelecimento&rdquo; para efeitos deste Termo
                        de Uso, que contrata a utiliza&ccedil;&atilde;o do
                        produto/servi&ccedil;o da Fidel;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: normal;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <strong
                        ><span style="color: black"
                            >(c)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;&nbsp;</span
                        ></strong
                    ><strong
                        ><span style="color: black"
                            >&quot;Usu&aacute;rio</span
                        ></strong
                    ><span style="color: black"
                        >&quot;: pessoa natural que utiliza os servi&ccedil;os
                        da Fidel, tamb&eacute;m denominada
                        &quot;cliente&quot;;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.4pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.4pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <strong
                        ><span style="color: black"
                            >(d)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;</span
                        ></strong
                    ><span style="color: black"
                        >&quot;<strong
                            >Pol&iacute;tica de Privacidade&quot;:</strong
                        >
                        documento pelo qual a Fidel esclarece em detalhes, o
                        tratamento de dados pessoais dos Usu&aacute;rios, em
                        atendimento &agrave; legisla&ccedil;&atilde;o vigente,
                        sobretudo, de acordo com as disposi&ccedil;&otilde;es da
                        Lei Geral de Prote&ccedil;&atilde;o de Dados (Lei n.
                        13.709/18);</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: normal;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <strong
                        ><span style="color: black"
                            >(e)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;</span
                        ></strong
                    ><strong
                        ><span style="color: black"
                            >&quot;LGPD&quot;:</span
                        ></strong
                    ><span style="color: black"
                        >&nbsp;Lei Geral de Prote&ccedil;&atilde;o de Dados ou
                        Lei n. 13.709/18;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <strong
                        ><span style="color: black"
                            >(f)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;&nbsp;</span
                        ></strong
                    ><strong
                        ><span style="color: black"
                            >&quot;Dados pessoais&quot;:</span
                        ></strong
                    ><span style="color: black"
                        >&nbsp;qualquer informa&ccedil;&atilde;o relacionada
                        &agrave;&nbsp;</span
                    >pessoa<span style="color: black"
                        >&nbsp;natural que a identifique, ou que, usada em
                        combina&ccedil;&atilde;o com outras
                        informa&ccedil;&otilde;es tratadas, identifiquem um
                        indiv&iacute;duo. Ainda, qualquer
                        informa&ccedil;&atilde;o por meio da qual a
                        identifica&ccedil;&atilde;o ou informa&ccedil;&atilde;o
                        de contato de uma pessoa natural seja
                        poss&iacute;vel;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <strong
                        ><span style="color: black"
                            >(g)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;</span
                        ></strong
                    ><strong
                        ><span style="color: black"
                            >&quot;Titular&quot;:</span
                        ></strong
                    ><span style="color: black"
                        >&nbsp;pessoa natural a quem se referem os dados
                        pessoais objeto do tratamento de dados pessoais;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <strong
                        ><span style="color: black"
                            >(h)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;</span
                        ></strong
                    ><strong
                        ><span style="color: black"
                            >&quot;Tratamento de dados pessoais&quot;:</span
                        ></strong
                    ><span style="color: black"
                        >&nbsp;considera-se tratamento de dado pessoal a coleta,
                        produ&ccedil;&atilde;o, recep&ccedil;&atilde;o,
                        classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o,
                        acesso, reprodu&ccedil;&atilde;o, transmiss&atilde;o,
                        distribui&ccedil;&atilde;o, processamento, arquivamento,
                        armazenamento, elimina&ccedil;&atilde;o,
                        avalia&ccedil;&atilde;o ou controle da
                        informa&ccedil;&atilde;o, modifica&ccedil;&atilde;o,
                        comunica&ccedil;&atilde;o, transfer&ecirc;ncia,
                        difus&atilde;o ou extra&ccedil;&atilde;o de dados de
                        pessoas naturais;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <strong
                        ><span style="color: black"
                            >(i)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp; &nbsp;</span
                        ></strong
                    ><span style="color: black"
                        >&quot;<strong>Consentimento&quot;:</strong>
                        autoriza&ccedil;&atilde;o livre, informada e
                        inequ&iacute;voca (sem deixar d&uacute;vidas) pela qual
                        o Usu&aacute;rio concorda com o tratamento de seus dados
                        pessoais para uma finalidade previamente
                        estipulada;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 35.45pt;
                        text-align: justify;
                        text-indent: -0.5pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <h1
                    style="
                        margin: 0cm 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;&nbsp;Contrata&ccedil;&atilde;o, requisitos
                    t&eacute;cnicos m&iacute;nimos para a
                    utiliza&ccedil;&atilde;o e legisla&ccedil;&atilde;o
                    aplic&aacute;vel
                </h1>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    O&nbsp;<strong>CONTRATANTE</strong> dever&aacute; proceder
                    com as seguintes condi&ccedil;&otilde;es
                    indispens&aacute;veis e obrigat&oacute;rias para
                    contrata&ccedil;&atilde;o, dispon&iacute;veis no site da
                    Fidel
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 71.45pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span style="color: black"
                        >Preencher o cadastro disponibilizado no aplicativo, com
                        informa&ccedil;&otilde;es solicitadas;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 71.45pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span style="color: black"
                        >Ler e aceitar os presentes &ldquo;Termos de Uso&rdquo;
                        e a &ldquo;Pol&iacute;tica de Privacidade&rdquo;;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 71.45pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span style="color: black"
                        >Escolher um Plano de Assinatura.</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    No momento do cadastro, o&nbsp;<strong>CONTRATANTE</strong>
                    dever&aacute; escolher um nome de usu&aacute;rio e senha
                    pessoal e intransfer&iacute;vel, por meio dos quais ele
                    ter&aacute; acesso ao Aplicativo e WEB (WEB, a partir do
                    plano Vip)&nbsp;&nbsp;Ambiente Virtual do Estabelecimento.
                    Ainda, deve informar um e-mail v&aacute;lido para contato,
                    comprometendo-se a n&atilde;o compartilhar nenhuma dessas
                    informa&ccedil;&otilde;es com terceiros e
                    responsabilizando-se exclusiva e pessoalmente pelo seu uso.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &Eacute; recomend&aacute;vel que a senha seja trocada
                    pelo&nbsp;<strong>CONTRATANTE</strong> periodicamente. Isto
                    pode ser feito atrav&eacute;s do acesso ao aplicativo ou
                    pela internet no ambiente WEB do Estabelecimento.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    O&nbsp;<strong>CONTRATANTE</strong> declara e se
                    responsabiliza pela veracidade de todos os dados por ele
                    fornecidos no momento de seu cadastro e em qualquer
                    atualiza&ccedil;&atilde;o posterior, ficando
                    respons&aacute;vel pela sua exatid&atilde;o e autenticidade.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    A Fidel &eacute; acess&iacute;vel exclusivamente pela
                    Internet, n&atilde;o havendo instala&ccedil;&atilde;o
                    f&iacute;sica nas m&aacute;quinas
                    do&nbsp;<strong>CONTRATANTE</strong>.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Fica a&nbsp;<strong>CONTRATADA</strong> isenta de qualquer
                    responsabilidade por problemas decorrentes da
                    interrup&ccedil;&atilde;o dos servi&ccedil;os pelo provedor
                    de acesso do&nbsp;<strong>CONTRATANTE</strong>, pela
                    interrup&ccedil;&atilde;o dos servi&ccedil;os em caso de
                    falta de energia el&eacute;trica, por falhas no sistema de
                    transmiss&atilde;o ou de roteamento no acesso &agrave;
                    Internet, por incompatibilidades no sistema do<strong
                        >&nbsp;CONTRATANTE</strong
                    >
                    com os sistemas do provedor de acesso ou por qualquer
                    a&ccedil;&atilde;o de terceiros que impe&ccedil;a a
                    presta&ccedil;&atilde;o do servi&ccedil;o resultante de caso
                    fortuito ou for&ccedil;a maior, nos termos do art. 393 do
                    C&oacute;digo Civil.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    As rela&ccedil;&otilde;es jur&iacute;dicas decorrentes do
                    presente contrato s&atilde;o regidas pela
                    legisla&ccedil;&atilde;o brasileira.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <h1
                    style="
                        margin: 0cm 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;Cadastro no sistema e Usu&aacute;rios
                    habilitados
                </h1>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    O&nbsp;<strong>CONTRATANTE</strong>, ao efetuar seu
                    cadastro, dever&aacute; aceitar os &ldquo;Termos e
                    Condi&ccedil;&otilde;es de Uso&rdquo; e a
                    &ldquo;Pol&iacute;tica de Privacidade&rdquo; em seu primeiro
                    acesso, vinculando-se aos direitos e
                    obriga&ccedil;&otilde;es previstos naquilo que lhe couber.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Tentamos fazer com que a Fidel esteja amplamente
                    dispon&iacute;vel para todos, mas seus clientes n&atilde;o
                    poder&atilde;o us&aacute;-la se forem menores de 13 anos.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 0cm;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Em nenhuma hip&oacute;tese ser&aacute; permitida a
                    cess&atilde;o, venda, aluguel ou outra forma de
                    transfer&ecirc;ncia da conta. Tamb&eacute;m n&atilde;o se
                    permitir&aacute; a utiliza&ccedil;&atilde;o ou
                    manuten&ccedil;&atilde;o de um mesmo&nbsp;<em>login</em> de
                    acesso por mais de uma pessoa e/ou acesso simult&acirc;neo.
                    Cada estabelecimento dever&aacute; possuir seu
                    respectivo&nbsp;<em>login</em> de acesso.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    O&nbsp;<strong>USU&Aacute;RIO</strong> garante e responde,
                    em qualquer caso, pela veracidade, exatid&atilde;o e
                    autenticidade dos dados informados e dever&aacute;:
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 0cm;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 72pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span style="color: black"
                        >Fornecer informa&ccedil;&otilde;es verdadeiras, exatas,
                        atuais e completas;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 72pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span style="color: black"
                        >Conservar e atualizar tais informa&ccedil;&otilde;es
                        para mant&ecirc;-las verdadeiras, exatas, atuais e
                        completas;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Somente ser&aacute; confirmado o cadastro do interessado que
                    preencher todos os campos obrigat&oacute;rios.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    O&nbsp;<em>login</em> de acesso &agrave; plataforma &eacute;
                    feito atrav&eacute;s da inser&ccedil;&atilde;o de nome de
                    usu&aacute;rio ou e-mail e senha, cabendo exclusivamente ao
                    Usu&aacute;rio cadastrado a guarda e o armazenamento
                    adequado desses dados, responsabilizando-se pessoalmente por
                    tudo que vier a ser realizado durante a
                    utiliza&ccedil;&atilde;o de sua conta.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    O USU&Aacute;RIO e o CONTRATANTE concordam que o acesso
                    poder&aacute; ser interrompido ou cancelado - independente
                    de aviso pr&eacute;vio - caso qualquer
                    informa&ccedil;&atilde;o fornecida seja falsa, incorreta,
                    desatualizada ou incompleta ou em caso de
                    viola&ccedil;&atilde;o dos presentes &ldquo;Termos e
                    Condi&ccedil;&otilde;es de Uso&rdquo;.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <h1
                    style="
                        margin: 0cm 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;&nbsp;Planos de assinatura, forma de pagamento e
                    reajuste
                </h1>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Os Planos de assinatura dispon&iacute;veis para o acesso
                    &agrave; carteira digital Fidel s&atilde;o:
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 54pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: normal;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span style="color: black">Plano&nbsp;</span>Gr&aacute;tis;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 54pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: normal;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    >Plano Econ&ocirc;mico;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 54pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: normal;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    >Plano Standard;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt 54pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: normal;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    >Plano Enterprise.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <h3
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: Candara, sans-serif; color: black"
                        >O pre&ccedil;o da mensalidade variar&aacute; de acordo
                        com o Plano de Assinatura contratado, o per&iacute;odo
                        contratado (mensal ou anual) e poder&aacute; ser pago
                        via cart&atilde;o de cr&eacute;dito.</span
                    >
                </h3>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 0cm;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Os valores referentes ao Plano de assinatura d&atilde;o
                    direito de acesso &agrave;s funcionalidades disponibilizadas
                    no aplicativo da Fidel, conforme o plano escolhido. Contudo,
                    qualquer conte&uacute;do extra como consultas,
                    esclarecimento de d&uacute;vidas, orienta&ccedil;&otilde;es
                    e eventuais novos produtos que n&atilde;o s&atilde;o parte
                    dos servi&ccedil;os contratados, ainda que possam ser
                    atendidos, ser&atilde;o feitos a crit&eacute;rio da equipe,
                    de forma gratuita ou onerosa, cabendo acordo entre as
                    partes.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Ao utilizar meios de pagamento&nbsp;<em>on-line</em>, fica
                    o&nbsp;<strong>CONTRATANTE</strong> ciente de que os
                    pagamentos ser&atilde;o feitos por interm&eacute;dio de
                    empresas terceirizadas, n&atilde;o possuindo a&nbsp;<strong
                        >CONTRATADA</strong
                    >
                    qualquer inger&ecirc;ncia sobre tal opera&ccedil;&atilde;o,
                    raz&atilde;o pela qual n&atilde;o poder&aacute; ser
                    responsabilizada pelos atos por elas praticados.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    A&nbsp;<strong>CONTRATADA</strong> n&atilde;o armazena dados
                    de cart&otilde;es de cr&eacute;dito do&nbsp;<strong
                        >CONTRATANTE</strong
                    >
                    e&nbsp;<strong>USU&Aacute;RIOS</strong>, n&atilde;o podendo
                    responder por fatos ocorridos em plataforma digital de
                    terceiros.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Ainda, a&nbsp;<strong>CONTRATADA</strong> n&atilde;o se
                    responsabiliza pelos casos em que as empresas operadoras dos
                    meios de pagamento vierem a admitir o uso de cart&otilde;es
                    por quem n&atilde;o for seu leg&iacute;timo titular.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Nos pagamentos via cart&atilde;o de cr&eacute;dito dos
                    Planos Mensal, Semestral ou Anual, sua assinatura
                    ser&aacute; renovada automaticamente a cada m&ecirc;s, bem
                    como o desconto do valor ocorrer&aacute; a partir da data de
                    vencimento estabelecida de acordo com a data da
                    contrata&ccedil;&atilde;o.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Para que seja cessada a renova&ccedil;&atilde;o
                    autom&aacute;tica, basta que o&nbsp;<strong
                        >CONTRATANTE</strong
                    >
                    cancele sua assinatura, mediante pr&eacute;via
                    notifica&ccedil;&atilde;o
                    &agrave;&nbsp;<strong>CONTRATADA</strong>. Ao cancelar,
                    o&nbsp;<strong>CONTRATANTE</strong> poder&aacute; continuar
                    usando a assinatura at&eacute; a pr&oacute;xima data de
                    cobran&ccedil;a.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Optando o&nbsp;<strong>CONTRATANTE</strong> pelo pagamento
                    via boleto, este ser&aacute; emitido no dia da
                    contrata&ccedil;&atilde;o e o acesso &agrave; plataforma
                    s&oacute; ser&aacute; permitido ap&oacute;s o pagamento.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 0cm;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Caso o&nbsp;<strong>CONTRATANTE</strong> opte pela
                    assinatura do Plano Semestral ou Anual e, durante o
                    per&iacute;odo de 6 (seis) ou 12 (doze) meses, decida deixar
                    de utilizar o aplicativo, dever&atilde;o ser pagos os meses
                    posteriores at&eacute; o fim do per&iacute;odo contratado,
                    bem como n&atilde;o ser&aacute; devida qualquer
                    devolu&ccedil;&atilde;o em rela&ccedil;&atilde;o aos meses
                    pagos.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Em caso de cobran&ccedil;a judicial ou extrajudicial,
                    incidir&atilde;o todas as despesas processuais ou
                    extrajudiciais, incluindo honor&aacute;rios
                    advocat&iacute;cios, honor&aacute;rios de peritos, custas
                    judiciais, emolumentos cartor&aacute;rios, dentre outros.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    No caso de cria&ccedil;&atilde;o de novos tributos, aumento
                    de custos, ou qualquer outro fato que ocasione o
                    desequil&iacute;brio econ&ocirc;mico-financeiro do contrato,
                    incluindo, mas n&atilde;o se limitando &agrave;
                    eleva&ccedil;&atilde;o de sal&aacute;rios dos colaboradores,
                    valores de hospedagem de site, e demais insumos
                    necess&aacute;rios ao fornecimento do servi&ccedil;o,
                    a&nbsp;<strong>CONTRATADA</strong> ter&aacute; o direito de
                    rever seus pre&ccedil;os de forma proporcional. De todo
                    modo, o&nbsp;<strong>CONTRATANTE</strong> sempre ser&aacute;
                    notificado sobre quaisquer altera&ccedil;&otilde;es nos
                    pre&ccedil;os.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 0cm;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <h1
                    style="
                        margin: 0cm 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    7.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;Altera&ccedil;&atilde;o dos Termos e
                    Condi&ccedil;&otilde;es de Uso e Pol&iacute;tica de
                    Privacidade
                </h1>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    A&nbsp;<strong>CONTRATADA</strong> poder&aacute; modificar,
                    a qualquer tempo, seus Termos e Condi&ccedil;&otilde;es de
                    Uso e sua Pol&iacute;tica de Privacidade, visando seu
                    aprimoramento e correspond&ecirc;ncia com os servi&ccedil;os
                    prestados ou para atender quest&otilde;es legais,
                    administrativas ou ordens judiciais.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    De todo modo, voc&ecirc; ser&aacute; avisado acerca da
                    ocorr&ecirc;ncia de modifica&ccedil;&otilde;es nestes
                    Termos, e caso voc&ecirc; n&atilde;o concorde com as
                    mudan&ccedil;as, voc&ecirc; poder&aacute; cancelar a sua
                    conta e a utiliza&ccedil;&atilde;o dos nossos
                    servi&ccedil;os. Esclarecemos que a partir do momento do
                    cancelamento, o&nbsp;<strong>CONTRATANTE</strong>
                    poder&aacute; usufruir do per&iacute;odo pago at&eacute; o
                    &uacute;ltimo dia anterior &agrave; data de vencimento do
                    respectivo Plano contratado.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Ressaltamos que em todas as hip&oacute;teses de
                    contrata&ccedil;&atilde;o dos Planos Mensal, Semestral ou
                    Anual, o&nbsp;<strong>CONTRATANTE</strong> possui 7 (sete)
                    dias para exercer seu direito de arrependimento, nos termos
                    do art. 49 do C&oacute;digo de Defesa do Consumidor (Lei
                    n&ordm; 8.078/1990).
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <h1
                    style="
                        margin: 0cm 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    8.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;&nbsp;Altera&ccedil;&atilde;o dos servi&ccedil;os e
                    funcionalidades do sistema
                </h1>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;A&nbsp;<strong>CONTRATADA</strong> poder&aacute;
                    realizar modifica&ccedil;&otilde;es (<em>updates</em>) nos
                    servi&ccedil;os pr&oacute;prios do sistema e funcionalidades
                    existentes, desde que sem alterar a presta&ccedil;&atilde;o
                    dos servi&ccedil;os contratados, a fim de promover a
                    melhoria do sistema ou sua adequa&ccedil;&atilde;o &agrave;
                    determinada imposi&ccedil;&atilde;o legal, o que n&atilde;o
                    implica em falha contratual ou em raz&atilde;o para
                    rescis&atilde;o do contrato pelo&nbsp;<strong
                        >CONTRATANTE.</strong
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <h1
                    style="
                        margin: 0cm 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    9.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;&nbsp;Pol&iacute;tica de utiliza&ccedil;&atilde;o de
                    dados pessoais
                </h1>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Ao acessar a plataforma da&nbsp;<strong>CONTRATADA</strong>,
                    o Usu&aacute;rio declara estar ciente da&nbsp;<u
                        >Pol&iacute;tica de Privacidade</u
                    >
                    por ela estabelecida e declara expressamente que aceita e
                    concorda com as previs&otilde;es nela descritas.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <h1
                    style="
                        margin: 0cm 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    10.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;&nbsp;Propriedade Intelectual
                </h1>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    O uso comercial das express&otilde;es &ldquo;FIDEL&rdquo;
                    como marca, nome empresarial ou nome de dom&iacute;nio, bem
                    como todo conte&uacute;do disponibilizado via plataforma,
                    incluindo, mas n&atilde;o se limitando a marcas, logotipos,
                    textos, gr&aacute;ficos, fotografias, v&iacute;deos,
                    conte&uacute;do de &aacute;udio, telas, programas de
                    computador, bancos de dados, arquivos de transmiss&atilde;o
                    e demais instrumentos que permitam a
                    utiliza&ccedil;&atilde;o da plataforma
                    Fidel<strong>&nbsp;</strong>pelo&nbsp;<strong
                        >CONTRATANTE</strong
                    >
                    e Usu&aacute;rio s&atilde;o propriedade
                    da&nbsp;<strong>CONTRATADA&nbsp;</strong>e est&atilde;o
                    protegidos pelas leis e tratados internacionais de direito
                    autoral, marcas, patentes de inven&ccedil;&atilde;o, modelos
                    de utilidade e desenhos industriais. A c&oacute;pia,
                    reprodu&ccedil;&atilde;o, distribui&ccedil;&atilde;o,
                    transmiss&atilde;o, publica&ccedil;&atilde;o, conex&atilde;o
                    ou qualquer outra forma de uso indevido dos referidos
                    conte&uacute;dos &eacute; proibida, salvo mediante
                    autoriza&ccedil;&atilde;o expressa
                    da&nbsp;<strong>CONTRATADA</strong>.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Quaisquer marcas exibidas na Fidel ou qualquer outro site
                    operado em conjunto com a Fidel n&atilde;o devem ser
                    consideradas como de dom&iacute;nio p&uacute;blico e
                    s&atilde;o de titularidade exclusiva
                    da&nbsp;<strong>CONTRATADA</strong>, de seus parceiros ou de
                    terceiros licenciantes, conforme o caso.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    As funcionalidades que comp&otilde;em a Fidel s&atilde;o
                    oferecidas na forma de presta&ccedil;&atilde;o de
                    servi&ccedil;o, n&atilde;o conferindo ao Usu&aacute;rio e/ou
                    Contratante nenhum direito sobre qualquer&nbsp;<em
                        >software</em
                    >
                    utilizado pela&nbsp;<strong>CONTRATADA</strong>, e/ou sobre
                    suas estruturas de inform&aacute;tica que sustentam a
                    plataforma Fidel.
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    A reprodu&ccedil;&atilde;o do conte&uacute;do
                    dispon&iacute;vel na&nbsp;<strong>Fidel</strong>, sem a
                    devida autoriza&ccedil;&atilde;o, ou ainda sem a
                    correspondente cita&ccedil;&atilde;o e refer&ecirc;ncia
                    &agrave; plataforma e
                    &agrave;&nbsp;<strong>CONTRATADA&nbsp;</strong>constituir&aacute;
                    infra&ccedil;&atilde;o aos direitos de propriedade
                    intelectual da&nbsp;<strong>CONTRATADA</strong>, e
                    sujeitar&aacute; o Usu&aacute;rio e/ou Contratante
                    respons&aacute;vel pela infra&ccedil;&atilde;o, &agrave;s
                    san&ccedil;&otilde;es administrativas, civis e criminais
                    cab&iacute;veis.
                </p>
                <h1
                    style="
                        margin: 12pt 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    11.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;Limita&ccedil;&otilde;es, atividades vedadas ao
                    Usu&aacute;rio e outras obriga&ccedil;&otilde;es
                </h1>
                <h3
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: Candara, sans-serif; color: black"
                        >&nbsp;</span
                    >
                </h3>
                <h3
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: Candara, sans-serif; color: black"
                        >A Fidel dever&aacute; ser utilizada exclusivamente para
                        as finalidades contratadas, n&atilde;o podendo
                        o&nbsp;<strong>CONTRATANTE</strong> permitir, facilitar,
                        utilizar ou fornecer os servi&ccedil;os ora contratados
                        a terceiros ou Usu&aacute;rios n&atilde;o habilitados.
                        Ainda, estes n&atilde;o poder&atilde;o ser utilizados
                        pelo&nbsp;<strong>CONTRATANTE</strong>, sob qualquer
                        circunst&acirc;ncia, para explora&ccedil;&atilde;o
                        comercial que n&atilde;o esteja dentro dos limites do
                        sistema de bonifica&ccedil;&atilde;o de seus
                        pr&oacute;prios clientes.</span
                    >
                </h3>
                <h3
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 0cm;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: Candara, sans-serif; color: black"
                        >&nbsp;</span
                    >
                </h3>
                <h3
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: Candara, sans-serif; color: black"
                        >&nbsp;Qualquer informa&ccedil;&atilde;o, materiais ou
                        outros direitos fornecidos dispostos nestes Termos e
                        Condi&ccedil;&otilde;es de Uso n&atilde;o s&atilde;o
                        transfer&iacute;veis e n&atilde;o s&atilde;o
                        sublicenci&aacute;veis
                        pelo&nbsp;<strong>CONTRATANTE</strong>.</span
                    >
                </h3>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <h3
                    style="
                        margin: 2pt 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: Candara, sans-serif; color: black"
                        >O&nbsp;<strong>CONTRATANTE</strong> concorda que
                        n&atilde;o se envolver&aacute; nem ajudar&aacute; - por
                        inten&ccedil;&atilde;o ou omiss&atilde;o - um terceiro
                        com o prop&oacute;sito de:</span
                    >
                </h3>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    &nbsp;
                </p>
                <h3
                    style="
                        margin: 2pt 0cm 0.0001pt 71.45pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span
                        style="font-family: Candara, sans-serif; color: black"
                        >obter acesso n&atilde;o autorizado &agrave; plataforma
                        ou &agrave; conta de outro Usu&aacute;rio;</span
                    >
                </h3>
                <h3
                    style="
                        margin: 2pt 0cm 0.0001pt 71.45pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span
                        style="font-family: Candara, sans-serif; color: black"
                        >fazer qualquer tentativa de contornar quaisquer
                        caracter&iacute;sticas de seguran&ccedil;a;</span
                    >
                </h3>
                <h3
                    style="
                        margin: 2pt 0cm 0.0001pt 71.45pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span
                        style="font-family: Candara, sans-serif; color: black"
                        >violar qualquer lei, estatuto, ordenan&ccedil;a ou
                        regulamento;</span
                    >
                </h3>
                <h3
                    style="
                        margin: 2pt 0cm 0.0001pt 71.45pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span
                        style="font-family: Candara, sans-serif; color: black"
                        >reproduzir, duplicar, copiar, vender ou revender os
                        servi&ccedil;os prestados
                        pela&nbsp;<strong>CONTRATADA</strong>;</span
                    >
                </h3>
                <h3
                    style="
                        margin: 2pt 0cm 0.0001pt 71.45pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span
                        style="font-family: Candara, sans-serif; color: black"
                        >interferir ou interromper os servi&ccedil;os prestados
                        pela&nbsp;<strong>CONTRATADA</strong>;</span
                    >
                </h3>
                <h3
                    style="
                        margin: 0cm 0cm 0.0001pt 71.45pt;
                        text-align: justify;
                        text-indent: -18pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: 'Noto Sans Symbols'; color: black"
                        >▪&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span
                    ><span
                        style="font-family: Candara, sans-serif; color: black"
                        >transferir a terceiros desautorizados os v&iacute;deos
                        ou materiais disponibilizados.</span
                    >
                </h3>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: normal;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <h1
                    style="
                        margin: 0cm 0cm 0.0001pt 36pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                    "
                >
                    12.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;&nbsp;Foro de Elei&ccedil;&atilde;o
                </h1>
                <p
                    style="
                        margin: 0cm 6.35pt 0.0001pt 0cm;
                        text-align: justify;
                        text-indent: 5.05pt;
                        line-height: 19.36px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span style="color: black">&nbsp;</span>
                </p>
                <h3
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: 16px;
                        font-family: Calibri, sans-serif;
                        color: rgb(31, 56, 99);
                        font-weight: normal;
                        font-style: normal;
                    "
                >
                    <span style="font-family: Candara, sans-serif; color: black"
                        >Qualquer controv&eacute;rsia decorrente da
                        interpreta&ccedil;&atilde;o ou aplica&ccedil;&atilde;o
                        do presente Contrato ser&aacute; resolvida de acordo com
                        o princ&iacute;pio da boa-f&eacute;, buscando as partes
                        a resolu&ccedil;&atilde;o prim&aacute;ria do conflito
                        por meio alternativo ao judicial, e caso n&atilde;o se
                        obtenha acordo, as partes elegem o foro da Comarca de
                        Vila Velha no Estado do Esp&iacute;rito Santo como
                        competente para processar e julgar quaisquer disputas
                        oriundas desta rela&ccedil;&atilde;o.</span
                    >
                </h3>
                <p
                    style="
                        margin: 0cm 6.1pt 0.0001pt 47.8pt;
                        text-align: justify;
                        text-indent: 0cm;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span
                        style="
                            font-size: 15px;
                            line-height: 16.8667px;
                            font-family: Calibri, sans-serif;
                            color: black;
                        "
                        >&nbsp;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 6.1pt 0.0001pt 47.8pt;
                        text-align: justify;
                        text-indent: 0cm;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span
                        style="
                            font-size: 15px;
                            line-height: 16.8667px;
                            font-family: Calibri, sans-serif;
                            color: black;
                        "
                        >&nbsp;</span
                    >
                </p>
                <p
                    style="
                        margin: 0cm 0cm 0.0001pt;
                        text-align: justify;
                        text-indent: 35.45pt;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                    "
                >
                    Atualiza&ccedil;&atilde;o: 22 de Fevereiro de 2021
                </p>
                <p
                    style="
                        margin: 0cm 6.1pt 0.0001pt 47.8pt;
                        text-align: justify;
                        text-indent: 0cm;
                        line-height: 18.4px;
                        font-size: medium;
                        font-family: Candara, sans-serif;
                        color: rgb(0, 0, 0);
                        font-style: normal;
                        font-weight: 400;
                        border: none;
                    "
                >
                    <span
                        style="
                            font-size: 15px;
                            line-height: 16.8667px;
                            font-family: Calibri, sans-serif;
                            color: black;
                        "
                        >&nbsp;</span
                    >
                </p>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>
