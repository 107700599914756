<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-circus"
    [fullScreen]="true"
    ><p style="color: white">Aguarde...</p></ngx-spinner
>
<div class="wrapper wrapper-contact">
    <app-nav-bar></app-nav-bar>

    <div class="wrapper-content contact">
        <!--<section class="hero" id="hero">
            <div class="cover">
                <div class="container">
                    <div class="d-block">
                        <div class="row">
                            <div class="col-12">
                                <h1 style="margin-top: 40px">
                                    Assine o Fidel e tenha acesso a recursos
                                    exclusivos
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->

        <section style="padding-top: 80px">
            <h2>Cadastre seu estabelecimento</h2>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mx-0">
                        <form id="msform" [formGroup]="valForm">
                            <ul id="progressbar">
                                <li
                                    [ngClass]="{
                                        'active active-syno': step >= 1
                                    }"
                                    id="step1"
                                >
                                    <strong>INFORMAÇÕES DA EMPRESA</strong>
                                </li>
                                <li
                                    [ngClass]="{
                                        'active active-syno': step >= 2
                                    }"
                                    id="step2"
                                >
                                    <strong>QUAL O ENDEREÇO?</strong>
                                </li>
                                <li
                                    [ngClass]="{
                                        'active active-syno': step >= 3
                                    }"
                                    id="step3"
                                >
                                    <strong>INFORMAÇÕES DE ACESSO</strong>
                                </li>
                            </ul>
                            <fieldset *ngIf="step == 1">
                                <app-dados-empresa
                                    [form]="valForm"
                                ></app-dados-empresa>
                            </fieldset>
                            <fieldset *ngIf="step == 2">
                                <app-endereco [form]="valForm"></app-endereco>
                            </fieldset>
                            <fieldset *ngIf="step == 3">
                                <app-informacoes-acesso
                                    [form]="valForm"
                                ></app-informacoes-acesso>
                            </fieldset>
                            <div class="back-div-v1">
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-3">
                                        <a
                                            *ngIf="step > 1"
                                            class="btn btn-outline-primary btn-oval"
                                            (click)="back()"
                                            href="javascript:void(0)"
                                        >
                                            Voltar
                                        </a>
                                    </div>

                                    <div class="col-md-3">
                                        <a
                                            *ngIf="step != 3"
                                            (click)="next()"
                                            class="btn btn-primary"
                                            >Próximo</a
                                        >
                                        <button
                                            *ngIf="step == 3"
                                            (click)="salvar()"
                                            class="btn btn-primary"
                                            type="button"
                                        >
                                            Finalizar
                                        </button>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<app-footer></app-footer>
