<nav
    class="navbar loaded-top"
    id="navbar"
    style="background-color: #220035 !important"
>
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <span class="brand logo">
                <img src="../../../../assets/img/logo_app.png" height="80" />
            </span>
        </a>

        <div class="collapse navbar-collapse" id="navbarMenu">
            <ul class="navbar-nav d-lg-flex flex-lg-row ml-lg-auto">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/">Início</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/pra-voce">Para Você</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/para-empresa"
                        >Para Empresas</a
                    >
                </li>-->
                <li class="nav-item">
                    <a class="nav-link" routerLink="/planos">Planos</a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        *ngIf="!session.token"
                        routerLink="/entrar"
                        >Login</a
                    >
                    <a
                        class="nav-link"
                        routerLink="/minha-conta"
                        *ngIf="session.token"
                        ><strong>Minha Conta</strong></a
                    >
                </li>
            </ul>
        </div>
    </div>
</nav>
