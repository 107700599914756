<div class="wrapper wrapper-contact">
    <app-nav-bar></app-nav-bar>

    <div class="wrapper-content contact">
        <section style="padding-top: 80px">
            <div class="container fidel-description">
                <h2>Como funciona?</h2>
                <br /><br />
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/WAK7qdHKgko"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>

                <br /><br />
                <br /><br />
                <br /><br />
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>
