import { Component, OnInit } from '@angular/core';
import { AccountsService } from '@services/accounts.service';
import { Session } from '@models/session.model';


@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
    session: Session;
    constructor(private accountsService: AccountsService) {
        this.accountsService
            .sessionSubject
            .subscribe((session: Session) => {
                if (session)
                    this.session = session;
                else
                    this.session = new Session();
            });
    }

    ngOnInit(): void {
    }

}
