import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import scriptjs from 'scriptjs';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';

import { Address } from '@models/address.model';

import { Plan } from '@models/plan.model';

import { AddressService } from '@services/address.service';
import { AssinaturasService } from '@services/assinaturas.service';
import { PlanosService } from '@services/planos.service';
import { FidelPagSeguroAddress, FidelPagSeguroSubscription, FidelPagSeguroSender, FidelPagSeguroDocument, FidelPagSeguroPhone, FidelPagSeguroPaymentMethod, FidelPagSeguroCreditCard, FidelPagSeguroHolder } from '@models/fidel.pagseguro.model';
import { NgxSpinnerService } from 'ngx-spinner';
declare let PagSeguroDirectPayment;

@Component({
    selector: 'app-assinatura',
    templateUrl: './assinatura.component.html',
    styleUrls: ['./assinatura.component.scss']
})
export class AssinaturaComponent implements OnInit {

    subscription: FidelPagSeguroSubscription;



    //const simpleObject = {} as ISimpleObject;

    credentials = {
        brand: '',
        token: '',
        hash: '',
        cartaoNumero: '',
        cartaoNome: '',
        cartaoDataValidade: '',
        cartaoCodigoSecret: '',
        endereoCep: '',
        enderecoNumero: '',
        enderecoLogradouro: '',
        enderecoBairro: '',
        enderecoCidade: '',
        enderecoEstado: '',
        dataNascimento: ''
    };


    valForm: FormGroup;

    plan: any;

    genders = [{
        id: 'M',
        description: 'Masculino'
    }, {
        id: 'F',
        description: 'Feminino'
    }];

    countries = [{
        id: 'us',
        description: 'EUA'
    }, {
        id: 'br',
        description: 'Brasil'
    }];

    planIdSubject: Subject<number>;
    addressSubject: Subject<Address>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private addressService: AddressService,
        private planosService: PlanosService,
        private assinaturasService: AssinaturasService,
        public zone: NgZone,
        private spinner: NgxSpinnerService
    ) {
        this.plan = new Plan();
        //this.subscription = new SubscriptionPagSeguro();
        this.planIdSubject = new Subject<number>();
        this.addressSubject = new Subject<Address>();
        this.subscription = new FidelPagSeguroSubscription();

    }

    ngOnInit(): void {
        scriptjs(
            'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js',
            () => {
                this.assinaturasService.getSession().subscribe((data) => {
                    this.initSession(data);
                });
            }
        );
        this.valForm = this.formBuilder.group({
            subscriptionNome: [null, Validators.required],
            subscriptionEmail: [null, Validators.required],
            subscriptionCpf: [null, Validators.required],
            subscriptionCelular: [null, Validators.required],
            subscriptionEstado: [null, Validators.required],
            subscriptionCidade: [null, Validators.required],
            subscriptionBairro: [null, Validators.required],
            subscriptionLogradouro: [null, Validators.required],
            subscriptionCep: [null, Validators.required],
            subscriptionNumero: [null, Validators.required],
            subscriptionCartaoNumero: [null, Validators.required],
            subscriptionCartaoNome: [null, Validators.required],
            subscriptionCartaoDataValidade: [null, Validators.required],
            subscriptionCartaoSecret: [null, Validators.required],
            subscriptionDataNascimento: [null, Validators.required]
        });
        this.valForm.get('subscriptionCartaoNumero').valueChanges.subscribe(value => {
            if (value.trim().length > 14) {
                this.getBrand();
            }
        });
        /*this.assinaturaForm = this.formBuilder.group({
            plan_id: [null, Validators.required],
            payment_method: [null, Validators.required],
            card_number: [null, Validators.required],
            card_cvv: [null, Validators.required],
            card_holder_name: [null, Validators.required],
            card_expiration_date: [null, Validators.required],
            customer: this.formBuilder.group({
                email: [null, [Validators.required, Validators.email]],
                name: [null, Validators.required],
                document_number: [null, Validators.required],
                gender: [null, Validators.required],
                born_at: [null, Validators.required],
                address: this.formBuilder.group({
                    street: [null, Validators.required],
                    street_number: [null, Validators.required],
                    complementary: [null, Validators.required],
                    neighborhood: [null, Validators.required],
                    zipcode: [null, Validators.required],
                    city: [null, Validators.required],
                    country: ['br', Validators.required]
                }),
                phone: this.formBuilder.group({
                    ddd: [null, Validators.required],
                    number: [null, Validators.required]
                }),
            })
        });*/

        this.addressSubject.subscribe((address: Address) => {
            // TODO: Consultar o CEP
        });


        this.planIdSubject.subscribe((plan_id: number) => {
            this.valForm.patchValue({ plan_id: plan_id });
        })

        this.activatedRoute.queryParams.subscribe(queryParams => {
            const planId = queryParams.plan_id;
            this.planIdSubject.next(planId);

            if (planId) {
                this.planosService.single(planId).subscribe((plan: any) => {
                    this.plan = plan;
                });
            }
        });
        this.spinner.show();
        this.assinaturasService.accountInformation().subscribe(result => {
            this.spinner.hide();


            this.valForm.controls.subscriptionNome.setValue(result.usuario.nome);
            this.valForm.controls.subscriptionEmail.setValue(result.usuario.email);
            this.valForm.controls.subscriptionCidade.setValue(result.empresa.cidade);
            this.valForm.controls.subscriptionCep.setValue(result.empresa.cep);
            this.valForm.controls.subscriptionLogradouro.setValue(result.empresa.endereco);
            this.valForm.controls.subscriptionNumero.setValue(result.empresa.numero);
            this.valForm.controls.subscriptionBairro.setValue(result.empresa.bairro);
        });
    }
    assinar($ev): void {

        $ev.preventDefault();

        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.spinner.show();
            this.getCreditCardToken().then(() => {
                var senderHash = PagSeguroDirectPayment.getSenderHash();

                const address = new FidelPagSeguroAddress();
                const sender = new FidelPagSeguroSender();
                const document = new FidelPagSeguroDocument();
                const phone = new FidelPagSeguroPhone();
                const paymentMethod = new FidelPagSeguroPaymentMethod();
                const creditCard = new FidelPagSeguroCreditCard();
                const holder = new FidelPagSeguroHolder();
                //ENDERECO
                address.city = this.valForm.value.subscriptionCidade;
                address.country = 'BRA';
                address.postalCode = this.valForm.value.subscriptionCep;
                address.state = this.valForm.value.subscriptionEstado;
                address.district = this.valForm.value.subscriptionBairro;
                address.number = this.valForm.value.subscriptionNumero;
                address.street = this.valForm.value.subscriptionLogradouro;

                //DOCUMENTO
                document.type = 'CPF';
                document.value = this.valForm.value.subscriptionCpf;
                //TELEFONE
                phone.number = this.valForm.value.subscriptionCelular.substring(2, this.valForm.value.subscriptionCelular - 3);
                phone.areaCode = this.valForm.value.subscriptionCelular.substring(0, 2);

                holder.phone = phone;
                holder.documents.push(document);
                holder.name = this.valForm.value.subscriptionCartaoNome;
                const diaNascimento = this.valForm.value.subscriptionDataNascimento.substring(0, 2);
                const mesNascimento = this.valForm.value.subscriptionDataNascimento.substring(2, 4);
                const anoNascimento = this.valForm.value.subscriptionDataNascimento.substring(4, this.valForm.value.subscriptionDataNascimento.length);

                holder.birthDate = `${diaNascimento}/${mesNascimento}/${anoNascimento}`;
                /* holder.birthDate = this.valForm.value.dataNascimento.split('-').reverse().join('/');*/
                holder.billingAddress = address;


                creditCard.token = this.credentials.token;
                creditCard.holder = holder;


                paymentMethod.type = 'CREDITCARD';
                paymentMethod.creditCard = creditCard;


                //SENDER
                sender.address = address;
                sender.documents.push(document);
                sender.email = this.valForm.value.subscriptionEmail;
                sender.hash = senderHash;
                sender.name = this.valForm.value.subscriptionCartaoNome;
                sender.phone = phone;



                this.subscription.plan = this.plan.referencia;
                this.subscription.sender = sender;
                this.subscription.paymentMethod = paymentMethod;

                this.assinaturasService
                    .subscribe(this.subscription)
                    .then(() => {
                        this.spinner.hide();

                        this.router.navigate(['/confirmacao-assinatura']);
                    }).catch(err => {
                        this.spinner.hide();
                    });

            }).catch(err => {
                this.showMsgError("Não foi possível validar o cartão junto ao operadora de cartão de crédito.");
            });
        }

    }


    consultarEnderecoPeloCEP(cep: string) {
        this.addressService.search(cep);
    }

    get subscriptionControls() {
        return this.valForm.controls;
    }

    get customerControls() {
        return (<FormGroup>this.subscriptionControls['customer']).controls;
    }

    get phoneControls() {
        return (<FormGroup>this.customerControls['phone']).controls;
    }

    get addressControls() {
        return (<FormGroup>this.customerControls['address']).controls;
    }

    async initSession(data) {
        await PagSeguroDirectPayment.setSessionId(data);
    }

    getBrand(): void {

        PagSeguroDirectPayment.getBrand({
            cardBin: this.valForm.controls.subscriptionCartaoNumero.value.substring(0, 6),
            success: function (response) {

                this.credentials.brand = response.brand.name;
            },
            error: function (response) {

                this.showMsgError("Não foi possível validar o cartão.");
            },
            complete: function (response) {


            }
        });
    }
    valuechange(newValue) {
        if (newValue.length > 7) {
            this.addressService.search(newValue).subscribe(result => {
                this.credentials.enderecoBairro = result.bairro;
                this.credentials.enderecoCidade = result.localidade;
                this.credentials.enderecoLogradouro = result.logradouro;
                this.credentials.enderecoEstado = result.uf;
                this.credentials.endereoCep = result.cep;
            });
        }
    }

    getCreditCardToken(): Promise<any> {
        return new Promise((resolve, reject) => {
            const mes = this.credentials.cartaoDataValidade.substring(0, 2);
            const ano = this.credentials.cartaoDataValidade.substring(2, this.credentials.cartaoDataValidade.length);
            PagSeguroDirectPayment.createCardToken({
                cardNumber: this.credentials.cartaoNumero, // Número do cartão de crédito
                brand: this.credentials.brand, // Bandeira do cartão
                cvv: this.credentials.cartaoCodigoSecret, // CVV do cartão
                expirationMonth: mes, // Mês da expiração do cartão
                expirationYear: ano, // Ano da expiração do cartão, é necessário os 4 dígitos.
                success: (response) => {
                    this.zone.run(() => {
                        this.credentials.token = response.card.token;
                        resolve({ token: response.card.token });

                    });
                },
                error(error) {
                    reject(error);
                }
            });
        });
    }
    showMsgError(msg) {
        this.spinner.hide();
        Swal.fire('Atenção', msg, 'error');

    }

}
