<div class="py-5 text-center">
    <!-- <img
        class="d-block mx-auto mb-4"
        src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg"
        alt=""
        width="72"
        height="72"
    />
   <h2>Minha Conta</h2>
    <p class="lead">
        Estamos sempre aprimorando o nosso app para garantir a melhor
        experiência para você e os seus clientes.
    </p>-->
</div>
