<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <form
                [formGroup]="valForm"
                class="form-validate form-horizontal"
                novalidate=""
            >
                <div class="card card-default">
                    <div class="card-body">
                        <p>
                            <strong
                                >Agora digite o seu e-mail e crie uma senha para
                                acessar a Fidel.</strong
                            >
                        </p>

                        <p>
                            Mas antes de tudo, leia nossos
                            <strong>termos de uso</strong> e
                            <strong>política de privacidade</strong> isso é
                            muito importante para que você não tenha nenhuma
                            dúvida.
                        </p>
                        <br /><br /><br />
                        <div class="form-group row text-center">
                            <div class="col-lg-6">
                                <label>E-mail</label>
                                <input
                                    class="form-control"
                                    type="email"
                                    formControlName="email"
                                    autocomplete="off"
                                    [formControl]="valForm.controls['email']"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['email'].hasError(
                                            'required'
                                        ) &&
                                        (valForm.controls['email'].dirty ||
                                            valForm.controls['email'].touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                            <div class="col-lg-6">
                                <label>Senha</label>

                                <div class="input-container">
                                    <input
                                        class="form-control"
                                        type="password"
                                        formControlName="senha"
                                        autocomplete="off"
                                        [formControl]="
                                            valForm.controls['senha']
                                        "
                                    />
                                    <!--  <i
                                        class="fa"
                                        [class.fa-check]="hide === true"
                                        [class.fa-close]="hide === false"
                                        aria-hidden="true"
                                    ></i>-->
                                </div>

                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls['senha'].hasError(
                                            'required'
                                        ) &&
                                        (valForm.controls['senha'].dirty ||
                                            valForm.controls['senha'].touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                            <div class="col-lg-3">
                                <label
                                    ><strong>CÓDIGO DE INDICAÇÃO</strong></label
                                >
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="codigoIndicacao"
                                    autocomplete="off"
                                    [formControl]="
                                        valForm.controls['codigoIndicacao']
                                    "
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        valForm.controls[
                                            'codigoIndicacao'
                                        ].hasError('required') &&
                                        (valForm.controls['codigoIndicacao']
                                            .dirty ||
                                            valForm.controls['codigoIndicacao']
                                                .touched)
                                    "
                                    >Este campo é obrigatório</span
                                >
                            </div>
                            <div class="col-md-9"></div>
                            <div class="col-md-6">
                                <p class="termo">
                                    Li e aceito os
                                    <a
                                        routerLink="/termo-uso"
                                        href="javascript:void(0)"
                                        ><strong>termos de uso</strong></a
                                    >
                                    e
                                    <a
                                        routerLink="/politica-privacidade"
                                        href="javascript:void(0)"
                                        ><strong
                                            >política de privacidade</strong
                                        ></a
                                    >
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
