<section class="block-content vehicles">
    <div class="container" style="max-width: 1500px !important">
        <header>
            <h3>Um pouco sobre a Fidel</h3>
        </header>
        <div class="row d-flex justify-content-center">
            <div class="col-md-6">
                <div class="card-available">
                    <i class="icon-motorcycle"></i>
                    <h3>+ 500 mil pontuações</h3>
                    <p>
                        Em pouco tempo de vida a Fidel reúne uma base de mais de
                        500 mil pontuações.
                    </p>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card-available">
                    <h3>+ 30 mil pontuações/mês</h3>
                    <p>Temos uma media de 30 mil pontuação por mês.</p>
                </div>
            </div>
        </div>
        <br />
        <div class="row d-flex justify-content-center">
            <div class="col-md-6">
                <div class="card-available">
                    <i class="icon-mini-van"></i>
                    <h3>Em todos os estados brasileiros</h3>
                    <p>
                        Em todo os estados brasileiros existe algum
                        estabelecimento que utiliza a Fidel para fidelizar seus
                        clientes.
                    </p>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card-available">
                    <i class="icon-truck"></i>
                    <h3>+ 85 mil usuários</h3>
                    <p>
                        Desde o nascimento da Fidel em 2019, reunimos mais de 85
                        mil usuários interessados em ser fidelizados.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
