<div class="wrapper wrapper-home">

    <app-menu></app-menu>

    <div class="wrapper-content">

        <section>

            <div class="cover-container d-flex h-100 p-3 mx-auto flex-column">

                <main role="main" class="inner cover confirmacao">
                    <h1 class="cover-heading">Agora é só aguardar a confirmação do pagamento.</h1>
                    <p class="lead">
                        Enviaremos um email confirmando o pagamento e a liberação da assinatura.
                    </p>
                    <p class="lead">
                        <a routerLink="/inicio" class="btn btn-lg btn-secondary">Voltar para a página inícial</a>
                    </p>
                </main>

            </div>

        </section>

    </div>

    <app-footer></app-footer>

</div>