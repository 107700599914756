<div class="wrapper wrapper-contact">

    <app-menu></app-menu>

    <div class="wrapper-contact contact">
        <section class="hero"
                 id="hero">
            <div class="cover">
                <div class="container">
                    <div class="d-block">
                        <div class="row">
                            <div class="col-12">
                                <h1 style="margin-top: 40px">
                                    Cancelar Assinatura da Fidel
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <section style="padding-top: 200px; padding-bottom: 150px;">

        <form [formGroup]="cancelSubscriptionForm"
              (ngSubmit)="cancelar()"
              novalidate>

            <div class="container">

                <h3>Por que você decidiu cancelar sua assinatura?</h3>

                <div class="row">
                    <div class="col">
                        <!-- Motivo -->
                        <div class="form-check"
                             *ngFor="let cancelation_reason of cancelation_reasons">
                            <label class="form-check-label">
                                <input type="radio"
                                       class="form-check-input"
                                       [value]="cancelation_reason.cancelation_reason_id"
                                       name="cancelation_reason_id"
                                       formControlName="cancelation_reason_id">
                                {{ cancelation_reason.description }}
                            </label>
                        </div>

                        <!-- Descrição -->
                        <textarea class="form-control"
                                  placeholder="Quer falar mais algo?"
                                  formControlName="description">
                        </textarea>

                    </div>
                </div>

                <div class="row"
                     style="padding-top: 15px;">
                    <div class="col">
                        <button type="submit"
                                class="btn btn-block btn-secondary text-uppercase">CONFIRMAR CANCELAMENTO</button>
                        <button type="button"
                                routerLink="/inicio"
                                class="btn btn-block btn-primary text-uppercase">VOLTAR</button>
                    </div>
                </div>
            </div>

        </form>

    </section>

    <app-footer></app-footer>

</div>
