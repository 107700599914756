<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-circus"
    [fullScreen]="true"
    ><p style="color: white">Aguarde...</p></ngx-spinner
>
<div class="row">
    <div class="col-md-12 payment">
        <h3>Minha Assinatura</h3>
    </div>
</div>

<div class="card">
    <div class="card-header" style="text-align: center">
        <h3>{{ rootAssinatura?.plano?.nome }}</h3>
    </div>

    <div class="card-body">
        <div class="card-body detalhes-correcao">
            <h4 class="titulo-categorizacao">DETALHES DA MINHA assinatura</h4>
            <div class="row linha-detalhes">
                <div class="col-md-4">
                    <p>
                        <strong class="title-item">Plano: </strong> <br />
                        {{ rootAssinatura?.plano?.nome }}
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b class="title-item">Inicio vigência</b><br />
                        {{
                            rootAssinatura?.assinatura?.permissao
                                ?.data_inicio_vigencia
                                | date: "dd/MM/yyyy HH:mm:ss"
                        }}
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b class="title-item">Fim vigência</b><br />
                        {{
                            rootAssinatura?.assinatura?.permissao
                                ?.data_fim_vigencia
                                | date: "dd/MM/yyyy HH:mm:ss"
                        }}
                    </p>
                </div>
            </div>
            <div class="row linha-detalhes">
                <div class="col-md-4">
                    <p>
                        <b class="title-item">Notificação via WhatsApp?</b
                        ><br />
                        <span
                            *ngIf="
                                rootAssinatura?.plano?.parametro
                                    ?.notificacao_whatsapp == true
                            "
                            class="badge badge-success badge-status"
                            data-placement="left"
                            tooltip="Sim"
                            >Sim</span
                        >
                        <span
                            *ngIf="
                                rootAssinatura?.plano?.parametro
                                    ?.notificacao_whatsapp == false
                            "
                            class="badge badge-danger badge-status"
                            data-placement="left"
                            tooltip="Não"
                            >Não</span
                        >
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b class="title-item">Notificação via Telegram?</b
                        ><br />
                        <span
                            *ngIf="
                                rootAssinatura?.plano?.parametro
                                    ?.notificacao_telegram == true
                            "
                            class="badge badge-success badge-status"
                            data-placement="left"
                            tooltip="Sim"
                            >Sim</span
                        >
                        <span
                            *ngIf="
                                rootAssinatura?.plano?.parametro
                                    ?.notificacao_telegram == false
                            "
                            class="badge badge-danger badge-status"
                            data-placement="left"
                            tooltip="Não"
                            >Não</span
                        >
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b class="title-item">Notificação via Push? </b><br />
                        <span
                            *ngIf="
                                rootAssinatura?.plano?.parametro
                                    ?.notificacao_push == true
                            "
                            class="badge badge-success badge-status"
                            data-placement="left"
                            tooltip="Sim"
                            >Sim</span
                        >
                        <span
                            *ngIf="
                                rootAssinatura?.plano?.parametro
                                    ?.notificacao_push == false
                            "
                            class="badge badge-danger badge-status"
                            data-placement="left"
                            tooltip="Não"
                            >Não</span
                        >
                    </p>
                </div>
            </div>
            <div class="row linha-detalhes">
                <div class="col-md-4">
                    <p>
                        <b class="title-item"
                            >Quantidade de promoções (cartão) </b
                        ><br />
                        {{
                            rootAssinatura?.plano?.parametro?.quantidade_cartoes
                        }}
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b class="title-item"
                            >Quantidade de notificações (mês)
                        </b>
                        <br />
                        {{
                            rootAssinatura?.plano?.parametro
                                ?.quantidade_notifacao_mes
                        }}
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b class="title-item">Quantidade de funcionário </b>
                        <br />
                        {{
                            rootAssinatura?.plano?.parametro
                                ?.quantidade_funcionarios
                        }}
                    </p>
                </div>
            </div>
            <div class="row linha-detalhes">
                <div class="col-md-4">
                    <p>
                        <b class="title-item"
                            >Quantidade de pontuações (mês)
                        </b>
                        <br />
                        {{
                            rootAssinatura?.plano?.parametro
                                ?.quantidade_pontuacao
                        }}
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b class="title-item">Acesso ao Painel web? </b> <br />
                        <span
                            *ngIf="
                                rootAssinatura?.plano?.parametro?.painel_web ==
                                true
                            "
                            class="badge badge-success badge-status"
                            data-placement="left"
                            tooltip="Sim"
                            >Sim</span
                        >
                        <span
                            *ngIf="
                                rootAssinatura?.plano?.parametro?.painel_web ==
                                false
                            "
                            class="badge badge-danger badge-status"
                            data-placement="left"
                            tooltip="Não"
                            >Não</span
                        >
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        <b class="title-item">Acesso a API Integração: </b>
                        <br />
                        <span
                            *ngIf="
                                rootAssinatura?.plano?.parametro
                                    ?.api_integracao == true
                            "
                            class="badge badge-success badge-status"
                            data-placement="left"
                            tooltip="Sim"
                            >Sim</span
                        >
                        <span
                            *ngIf="
                                rootAssinatura?.plano?.parametro
                                    ?.api_integracao == false
                            "
                            class="badge badge-danger badge-status"
                            data-placement="left"
                            tooltip="Não"
                            >Não</span
                        >
                    </p>
                </div>
            </div>
        </div>

        <br /><br />
        <h4 *ngIf="!rootAssinatura?.plano?.free">Detalhes de cobrança</h4>
        <br />
        <table class="table" *ngIf="!rootAssinatura?.plano?.free">
            <thead>
                <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Data cobrança</th>
                    <th scope="col">Total</th>
                    <th scope="col">Status</th>
                    <th scope="col">Ação</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="
                        let root of rootAssinatura?.assinatura?.mensais;
                        let i = index
                    "
                >
                    <td>
                        {{ i + 1 }}
                    </td>
                    <td>
                        {{ root.data_agendamento | date: "dd/MM/yyyy HH:mm:ss" }}
                    </td>
                    <td>{{ root.valor | currency: "BRL":true:"1.2-2" }}</td>
                    <td>
                        <span
                            *ngIf="
                                root.statusCode === 4 ||
                                root.statusCode === 6 ||
                                root.statusCode === 3
                            "
                            class="badge badge-danger"
                        >
                            {{ root.status }}
                        </span>
                        <span
                            *ngIf="root.statusCode === 1"
                            class="badge badge-dark"
                        >
                            {{ root.status }}
                        </span>
                        <span
                            *ngIf="root.statusCode === 2"
                            class="badge badge-info"
                        >
                            {{ root.status }}
                        </span>
                        <span
                            *ngIf="root.statusCode === 5"
                            class="badge badge-success"
                        >
                            {{ root.status }}
                        </span>
                    </td>
                    <td>
                        <button
                            *ngIf="root.statusCode === 6"
                            class="btn btn-sm btn-primary"
                            (click)="reprocessamento()"
                        >
                            Reprocessar
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer">
        <button
            style="width: 100% !important"
            *ngIf="!rootAssinatura?.plano?.free"
            type="button"
            class="btn btn-danger"
            (click)="cancelar()"
        >
            Cancelar assinatura
        </button>
    </div>
</div>
