<div class="row">
    <div class="col-md-12 myaccount">
        <h3>Meus dados</h3>
    </div>
</div>

<form [formGroup]="meusDadosForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label for="firstName">Nome</label>
                <input type="text" disabled formControlName="nome" class="form-control" [ngClass]="{ 'is-invalid': form['nome'].invalid, 'is-valid' : form['nome'].dirty && !form['nome'].invalid }" required />
                <div class="invalid-feedback" *ngIf="form['nome'].invalid">
                    <div *ngIf="form['nome'].errors.required">Nome é obrigatório.</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" disabled class="form-control" id="email" formControlName="email" [ngClass]="{ 'is-invalid': form['email'].invalid, 'is-valid' : form['email'].dirty && !form['email'].invalid }" required />
                <div class="invalid-feedback" *ngIf="form['email'].invalid">
                    <div *ngIf="form['email'].errors.required">Email é obrigatório.</div>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label for="celular">Celular</label>
                <input type="text" disabled class="form-control" id="celular" formControlName="celular" [ngClass]="{ 'is-invalid': form['celular'].invalid, 'is-valid' : form['celular'].dirty && !form['celular'].invalid }" required />
                <div class="invalid-feedback" *ngIf="form['celular'].invalid">
                    <div *ngIf="form['celular'].errors.required">Celular é obrigatório.</div>
                </div>
            </div>
        </div>
    </div>

    <!--
    <div class="mb-3">
        <label for="address2">Address 2 <span class="text-muted">(Optional)</span></label>
        <input type="text" class="form-control" id="address2" placeholder="Apartment or suite" />
    </div>

    <div class="row">
        <div class="col-md-5 mb-3">
            <label for="country">Country</label>
            <select class="custom-select d-block w-100" id="country" required="">
                <option value="">Choose...</option>
                <option>United States</option>
            </select>
            <div class="invalid-feedback">
                Please select a valid country.
            </div>
        </div>
        <div class="col-md-4 mb-3">
            <label for="state">State</label>
            <select class="custom-select d-block w-100" id="state" required="">
                <option value="">Choose...</option>
                <option>California</option>
            </select>
            <div class="invalid-feedback">
                Please provide a valid state.
            </div>
        </div>
        <div class="col-md-3 mb-3">
            <label for="zip">Zip</label>
            <input type="text" class="form-control" id="zip" placeholder="" required="" />
            <div class="invalid-feedback">Zip code required.</div>
        </div>
    </div>
    <hr class="mb-4" />
    <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="same-address" />
        <label class="custom-control-label" for="same-address">Shipping address is the same as my billing address</label>
    </div>
    <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="save-info" />
        <label class="custom-control-label" for="save-info">Save this information for next time</label>
    </div>
    <hr class="mb-4" />

    <h4 class="mb-3">Payment</h4>

    <div class="d-block my-3">
        <div class="custom-control custom-radio">
            <input id="credit" name="paymentMethod" type="radio" class="custom-control-input" checked="" required="" />
            <label class="custom-control-label" for="credit">Credit card</label>
        </div>
        <div class="custom-control custom-radio">
            <input id="debit" name="paymentMethod" type="radio" class="custom-control-input" required="" />
            <label class="custom-control-label" for="debit">Debit card</label>
        </div>
        <div class="custom-control custom-radio">
            <input id="paypal" name="paymentMethod" type="radio" class="custom-control-input" required="" />
            <label class="custom-control-label" for="paypal">Paypal</label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 mb-3">
            <label for="cc-name">Name on card</label>
            <input type="text" class="form-control" id="cc-name" placeholder="" required="" />
            <small class="text-muted">Full name as displayed on card</small>
            <div class="invalid-feedback">Name on card is required</div>
        </div>
        <div class="col-md-6 mb-3">
            <label for="cc-number">Credit card number</label>
            <input type="text" class="form-control" id="cc-number" placeholder="" required="" />
            <div class="invalid-feedback">
                Credit card number is required
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 mb-3">
            <label for="cc-expiration">Expiration</label>
            <input type="text" class="form-control" id="cc-expiration" placeholder="" required="" />
            <div class="invalid-feedback">Expiration date required</div>
        </div>
        <div class="col-md-3 mb-3">
            <label for="cc-expiration">CVV</label>
            <input type="text" class="form-control" id="cc-cvv" placeholder="" required="" />
            <div class="invalid-feedback">Security code required</div>
        </div>
    </div> -->
    <hr class="mb-4" />
    <button class="btn btn-primary btn-lg btn-block" type="submit" disabled>
        Salvar
    </button>
</form>
