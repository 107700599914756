import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AccountsService } from '../services/accounts.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private accountService: AccountsService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const session = this.accountService.session;
        const isLoggedIn = session && session.token;
        const isApiUrl =
            request.url.startsWith(environment.api.backoffice.url) ||
            request.url.startsWith(environment.api.enterprise.url) ||
            request.url.startsWith(environment.api.payment.url) ||
            request.url.startsWith(environment.api.points.url);

        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${session.token}`
                }
            });
        }

        return next.handle(request);
    }
}
