import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    aceito: boolean;
    constructor() { }

    ngOnInit(): void {
        const termo = JSON.parse(localStorage.getItem('@fidel-termo'));
        if (termo != null) {
            this.aceito = termo.aceito;
        }
    }

    aceitar(): void {

        localStorage.setItem('@fidel-termo', JSON.stringify({ aceito: true }));
        this.aceito = true;

    }
}
