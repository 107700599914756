import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { Company } from '../models/company.model';
import { Session } from '../models/session.model';

@Injectable({
    providedIn: 'root'
})
export class AccountsService implements OnInit {
    public sessionSubject: BehaviorSubject<Session>;
    public session: Session;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.session = JSON.parse(localStorage.getItem('@fidel-session'));

        if (!this.session)
            this.session = new Session();

        this.sessionSubject = new BehaviorSubject<Session>(this.session);
    }

    ngOnInit() {
        this.sessionSubject.subscribe((session: Session) => {
            if (session)
                this.session = session;
            else
                this.session = new Session();
        });
    }

    signIn(username: string, password: string) {
        return this.http
            .post<Session>(`${environment.api.enterprise.url}/v1/session`, {
                email: username,
                senha: password,
                playerid: 'web'
            })
            .pipe(
                map((session: Session) => {
                    localStorage.setItem('@fidel-session', JSON.stringify(session));
                    this.sessionSubject.next(session);
                    this.session = session;
                    return session;
                })
            );
    }

    signOut() {
        localStorage.removeItem('@fidel-session');
        this.sessionSubject.next(null);
        this.router.navigate(['/entrar']);
    }

    register(company: Company) {
        return this.http.post(`${environment.api.backoffice.url}/v1/company`, company);
    }

    getInformation() {
        return this.http.get(`${environment.api.enterprise.url}/v2/user/userInformation`);
    }
}
