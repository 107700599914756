import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sessao-loja',
  templateUrl: './sessao-loja.component.html',
  styleUrls: ['./sessao-loja.component.scss']
})
export class SessaoLojaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
