<!-- Hotjar Tracking Code for www.fidel.com.br -->
<script>
    (function (h, o, t, j, a, r) {
        h.hj =
            h.hj ||
            function () {
                (h.hj.q = h.hj.q || []).push(arguments);
            };
        h._hjSettings = { hjid: 2265517, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
</script>

<div class="wrapper wrapper-contact">
    <app-nav-bar></app-nav-bar>
    <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.8)"
        size="medium"
        color="#fff"
        type="ball-circus"
        [fullScreen]="true"
        ><p style="color: white">Aguarde...</p></ngx-spinner
    >
    <div class="wrapper-content contact">
        <!-- <section class="hero" id="hero">
            <div class="cover">
                <div class="container">
                    <div class="d-block">
                        <div class="row">
                            <div class="col-12">
                                <h1 style="margin-top: 40px">
                                    Assine o Fidel e tenha acesso a recursos
                                    exclusivos
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->

        <section class="block-content maps" style="padding-top: 180px">
            <div class="container">
                <div class="row pin">
                    <div class="col-12">
                        <h3>
                            Escolha o plano mais adequado para o seu negócio.
                        </h3>
                    </div>
                    <div class="col-12"></div>
                </div>
            </div>
        </section>

        <section class="pricing py-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4" *ngFor="let plan of plans">
                        <div class="card mb-5 mb-lg-0">
                            <div class="card-body">
                                <h5
                                    style="font-size: 19px"
                                    class="card-title text-muted text-uppercase text-center"
                                >
                                    {{ plan.nome }}
                                </h5>
                                <h6 class="card-price text-center">
                                    {{
                                        plan.valorMensal
                                            | currency: "BRL":true:"1.2-2"
                                    }}<span
                                        class="period"
                                        style="font-size: 14px"
                                        >/Mês
                                    </span>
                                </h6>
                                <hr />
                                <table class="table">
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let plan_topic of plan.itens
                                            "
                                        >
                                            <td
                                                style="
                                                    font-size: 12px;
                                                    text-align: left;
                                                "
                                            >
                                                {{ plan_topic.nome }}
                                            </td>
                                            <td
                                                style="
                                                    font-size: 12px;
                                                    font-weight: bold;
                                                "
                                            >
                                                {{ plan_topic.descricao }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <ul class="fa-ul">
                                    <li *ngFor="let plan_topic of plan.itens">
                                      <span class="fa-li">
                                            <i
                                                class="fa"
                                                [class.fa-check]="
                                                    plan_topic.situation === 1
                                                "
                                                [class.fa-close]="
                                                    plan_topic.situation === 2
                                                "
                                                aria-hidden="true"
                                            ></i>
                                        </span>

                                        {{ plan_topic.descricao }}
                                        {{ plan_topic.nome }}
                                    </li>
                                </ul>-->

                                <button
                                    *ngIf="!plan.free"
                                    (click)="assinar(plan.codigo)"
                                    class="btn btn-primary btn-lg btn-oval col-md-12"
                                >
                                    Assinar
                                </button>
                                <a
                                    *ngIf="plan.free"
                                    class="btn btn-outline-primary btn-lg btn-oval col-md-12"
                                    routerLink="/registrar"
                                    href="javascript:void(0)"
                                >
                                    Faça seu cadastro
                                </a>

                                <ng-template #isSubscribed>
                                    <button
                                        (click)="
                                            cancelar(plan.subscriptions[0].id)
                                        "
                                        class="btn btn-block btn-primary text-uppercase"
                                    >
                                        Cancelar
                                    </button>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="warning">
            <div
                class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center"
            >
                <p class="lead">
                    Perguntas frequentes sobre a assinatura dos planos?
                </p>
            </div>

            <p>
                <b>Meu plano ficará ativo para sempre se eu não cancelar?</b>
            </p>

            <p>
                * Não, os planos são válidos por 12 meses a partir da data da
                ativação da assinatura, após isso será enviado um email
                solicitando cancelamento ou nova assinatura.
            </p>

            <p>
                <b>Posso cancelar meu plano a qualquer momento?</b>
            </p>

            <p>
                * Após a confirmação da assinatura será possível cancelar o
                plano a qualquer momento.
            </p>

            <p>
                <b
                    >Ao cancelar o plano, a cobrança do mês atual ainda será
                    realizada?</b
                >
            </p>

            <p>
                Sim, a fatura do mês vigente será cobrada normalmente e seu
                plano ficará ativo até o final do mês.
            </p>
        </section>
    </div>
</div>

<app-footer></app-footer>
