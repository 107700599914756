import { Component, NgZone, OnInit } from '@angular/core';
import scriptjs from 'scriptjs';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';

import { Address } from '@models/address.model';

import { Plan } from '@models/plan.model';

import { AddressService } from '@services/address.service';
import { AssinaturasService } from '@services/assinaturas.service';
import { PlanosService } from '@services/planos.service';
import { FidelPagSeguroCardAddress, FidelPagSeguroCardSubscription, FidelPagSeguroCardSender, FidelPagSeguroCardDocument, FidelPagSeguroCardPhone, FidelPagSeguroCardPaymentMethod, FidelPagSeguroCardCreditCard, FidelPagSeguroCardHolder } from '@models/fidel.pagseguro.card.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare let PagSeguroDirectPayment;
@Component({
    selector: 'app-alterar-forma-pagamento',
    templateUrl: './alterar-forma-pagamento.component.html',
    styleUrls: ['./alterar-forma-pagamento.component.scss']
})
export class AlterarFormaPagamentoComponent implements OnInit {
    valForm: FormGroup;
    paymentMethod: FidelPagSeguroCardPaymentMethod;
    credentials = {
        brand: '',
        token: '',
        hash: '',
        cartaoNumero: '',
        cartaoNome: '',
        cartaoDataValidade: '',
        cartaoCodigoSecret: '',
        endereoCep: '',
        enderecoNumero: '',
        enderecoLogradouro: '',
        enderecoBairro: '',
        enderecoCidade: '',
        enderecoEstado: '',
        dataNascimento: ''
    };
    constructor(private activatedRoute: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private addressService: AddressService,
        private planosService: PlanosService,
        private assinaturasService: AssinaturasService,
        public zone: NgZone,
        private spinner: NgxSpinnerService) { }

    ngOnInit(): void {
        scriptjs(
            'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js',
            () => {
                this.assinaturasService.getSession().subscribe((data) => {
                    this.initSession(data);
                });
            }
        );
        this.valForm = this.formBuilder.group({
            subscriptionCpf: [null, Validators.required],
            subscriptionEstado: [null, Validators.required],
            subscriptionCidade: [null, Validators.required],
            subscriptionBairro: [null, Validators.required],
            subscriptionLogradouro: [null, Validators.required],
            subscriptionCep: [null, Validators.required],
            subscriptionNumero: [null, Validators.required],
            subscriptionCartaoNumero: [null, Validators.required],
            subscriptionCartaoNome: [null, Validators.required],
            subscriptionCartaoDataValidade: [null, Validators.required],
            subscriptionCartaoSecret: [null, Validators.required],
            subscriptionDataNascimento: [null, Validators.required],
            subscriptionCelular: [null, Validators.required],
        });
        this.valForm.get('subscriptionCartaoNumero').valueChanges.subscribe(value => {
            if (value.trim().length > 14) {
                this.getBrand();
            }
        });

    }
    alterar($ev): void {

        $ev.preventDefault();

        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.spinner.show();
            this.getCreditCardToken().then(() => {
                var senderHash = PagSeguroDirectPayment.getSenderHash();

                this.paymentMethod = new FidelPagSeguroCardPaymentMethod();
                const address = new FidelPagSeguroCardAddress();
                const sender = new FidelPagSeguroCardSender();
                const document = new FidelPagSeguroCardDocument();
                const phone = new FidelPagSeguroCardPhone();
                const creditCard = new FidelPagSeguroCardCreditCard();
                const holder = new FidelPagSeguroCardHolder();
                //ENDERECO
                address.city = this.valForm.value.subscriptionCidade;
                address.country = 'BRA';
                address.postalCode = this.valForm.value.subscriptionCep;
                address.state = this.valForm.value.subscriptionEstado;
                address.district = this.valForm.value.subscriptionBairro;
                address.number = this.valForm.value.subscriptionNumero;
                address.street = this.valForm.value.subscriptionLogradouro;

                //DOCUMENTO
                document.type = 'CPF';
                document.value = this.valForm.value.subscriptionCpf;
                //TELEFONE
                phone.number = this.valForm.value.subscriptionCelular.substring(2, this.valForm.value.subscriptionCelular - 3);
                phone.areaCode = this.valForm.value.subscriptionCelular.substring(0, 2);

                holder.phone = phone;
                holder.documents.push(document);
                holder.name = this.valForm.value.subscriptionCartaoNome;
                const diaNascimento = this.valForm.value.subscriptionDataNascimento.substring(0, 2);
                const mesNascimento = this.valForm.value.subscriptionDataNascimento.substring(2, 4);
                const anoNascimento = this.valForm.value.subscriptionDataNascimento.substring(4, this.valForm.value.subscriptionDataNascimento.length);

                holder.birthDate = `${diaNascimento}/${mesNascimento}/${anoNascimento}`;
                /* holder.birthDate = this.valForm.value.dataNascimento.split('-').reverse().join('/');*/
                holder.billingAddress = address;

                creditCard.token = this.credentials.token;
                creditCard.holder = holder;

                //SENDER
                //sender.address = address;
                //sender.documents.push(document);
                //sender.email = this.valForm.value.subscriptionEmail;
                sender.hash = senderHash;
                // sender.name = this.valForm.value.subscriptionCartaoNome;
                //sender.phone = phone;

                this.paymentMethod.type = 'CREDITCARD';
                this.paymentMethod.creditCard = creditCard;
                this.paymentMethod.sender = sender;

                this.assinaturasService
                    .chagenPaymentMethod(this.paymentMethod)
                    .then(() => {
                        this.spinner.hide();
                        Swal.fire(
                            'Cartão Alterado!',
                            'Seu metodo de pagamento foi alterado com sucesso.',
                            'success'
                        );

                    }).catch(err => {
                        this.spinner.hide();
                    });

            }).catch(err => {
                this.showMsgError("Não foi possível validar o cartão junto ao operadora de cartão de crédito.");
            });
        }

    }
    getCreditCardToken(): Promise<any> {
        return new Promise((resolve, reject) => {
            const mes = this.credentials.cartaoDataValidade.substring(0, 2);
            const ano = this.credentials.cartaoDataValidade.substring(2, this.credentials.cartaoDataValidade.length);
            PagSeguroDirectPayment.createCardToken({
                cardNumber: this.credentials.cartaoNumero, // Número do cartão de crédito
                brand: this.credentials.brand, // Bandeira do cartão
                cvv: this.credentials.cartaoCodigoSecret, // CVV do cartão
                expirationMonth: mes, // Mês da expiração do cartão
                expirationYear: ano, // Ano da expiração do cartão, é necessário os 4 dígitos.
                success: (response) => {
                    this.zone.run(() => {
                        this.credentials.token = response.card.token;
                        resolve({ token: response.card.token });

                    });
                },
                error(error) {
                    reject(error);
                }
            });
        });
    }
    valuechange(newValue) {
        if (newValue.length > 7) {
            this.addressService.search(newValue).subscribe(result => {
                this.credentials.enderecoBairro = result.bairro;
                this.credentials.enderecoCidade = result.localidade;
                this.credentials.enderecoLogradouro = result.logradouro;
                this.credentials.enderecoEstado = result.uf;
                this.credentials.endereoCep = result.cep;
            });
        }
    }
    showMsgError(msg) {
        this.spinner.hide();
        Swal.fire('Atenção', msg, 'error');

    }
    getBrand(): void {

        PagSeguroDirectPayment.getBrand({
            cardBin: this.valForm.controls.subscriptionCartaoNumero.value.substring(0, 6),
            success: function (response) {
                this.credentials.brand = response.brand.name;
            },
            error() {
                this.showMsgError("Não foi possível validar o cartão.");
            },
            complete: function (response) {


            }
        });
    }
    async initSession(data) {
        await PagSeguroDirectPayment.setSessionId(data);
    }

}
