import { Component, OnInit } from '@angular/core';
import { SubscriptionsService } from '@services/subscriptions.service';
import { RootAssinatura } from '@models/fidel.assinatura.model';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { AssinaturasService } from '@services/assinaturas.service';

@Component({
    selector: 'app-minha-assinatura',
    templateUrl: './minha-assinatura.component.html',
    styleUrls: ['./minha-assinatura.component.scss']
})
export class MinhaAssinaturaComponent implements OnInit {


    rootAssinatura: RootAssinatura;


    constructor(
        //private subscriptionsService: SubscriptionsService,
        private subscriptionsService: AssinaturasService,
        private spinner: NgxSpinnerService
    ) {

        this.rootAssinatura = new RootAssinatura();
    }

    ngOnInit(): void {
        this.spinner.show();
        this.subscriptionsService
            .searchSignature()
            .subscribe((subscriptions: any) => {
                this.spinner.hide();
                this.rootAssinatura = subscriptions as RootAssinatura;
            });
    }

    cancelar() {
        Swal.fire({
            title: 'Cancelamento de assinatura',
            text: "Você realmente deseja cancelar sua assinatura?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {

                this.spinner.show();
                this.subscriptionsService
                    .cancelSubscribe()
                    .subscribe((subscriptions: any) => {
                        this.spinner.hide();
                        Swal.fire(
                            'Assinatura cancelada!',
                            'Sua assinatura foi cancelada com sucesso.',
                            'success'
                        );
                    });

            }
        })
    }

    reprocessamento() {
        this.spinner.show();
        this.subscriptionsService
            .paymentRetry().then((subscriptions: any) => {
                this.spinner.hide();
                Swal.fire(
                    'Sucesso',
                    'Sua solicitação de retentativa de pagamento foi feita com sucesso, você será notificado quanto tudo estiver certo.',
                    'success'
                );
            }).catch(err => {
                this.spinner.hide();
            }).finally(() => {
                this.spinner.hide();
            });
    }
}
