

export class Item {
    nome: string;
    descricao: string;
}

export class Plano {
    codigo: number;
    descricao: string;
    nome: string;
    referencia: string;
    free: boolean;
    itens: Item[];
    parametro: Parametro;
    constructor() {
        this.itens = new Array<Item>();
        this.parametro = new Parametro();
    }
}

export interface Transaco {
    id_transacao: number;
    codigo: string;
    data_cadastro: Date;
    status: string;
    id_fatura: number;
    data_evento: Date;
    codigoStatus: number;
}

export class Mensal {
    id_fatura: number;
    id_assinatura: number;
    codigo: string;
    status: string;
    statusCode: number;
    data_cadastro: Date;
    valor: string;
    ultimo_evento: Date;
    data_agendamento?: any;
    transacoes: Transaco[]
    constructor() {
        this.transacoes = new Array<Transaco>();
    }
}
export class Parametro {
    id_parametrizaca_grupo_sistema: number;
    id_sistema_grupo: number;
    notificacao_whatsapp: boolean;
    notificacao_telegram: boolean;
    quantidade_cartoes: number;
    notificacao_push: boolean;
    quantidade_notifacao_mes: number;
    quantidade_funcionarios: number;
    quantidade_pontuacao: number;
    data_cadastro: Date;
    painel_web: boolean;
    api_integracao: boolean;
}
export class Permissao {
    id_sistema_grupo_usuario: number;
    data_cadastro: Date;
    id_usuario: number;
    data_inicio_vigencia: Date;
    data_fim_vigencia: Date;
    data_desativacao?: any;
    id_sistema_grupo: number;
    id_assinatura: number;
}

export class Assinatura {
    id_assinatura: number;
    id_informacao_plano: number;
    id_usuario: number;
    referencia: string;
    codigo: string;
    status: string;
    data_cadastro: Date;
    data_desativacao?: any;
    mensais: Mensal[];
    permissao: Permissao;
    constructor() {
        this.mensais = new Array<Mensal>();
        this.permissao = new Permissao();
    }
}

export class RootAssinatura {
    plano: Plano;
    assinatura: Assinatura;
    constructor() {
        this.plano = new Plano();
        this.assinatura = new Assinatura();
    }
}



