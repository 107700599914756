import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sessao-sobre',
  templateUrl: './sessao-sobre.component.html',
  styleUrls: ['./sessao-sobre.component.scss']
})
export class SessaoSobreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
