import { PlanTopic } from './plan-topic.model';

export class Plan {
    id: number;
    name: String;
    amount: number;
    days: number;
    trial_days: number;
    plan_topics: Array<PlanTopic>;
    constructor() {
        this.plan_topics = new Array<PlanTopic>();

    }
}
