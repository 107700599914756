<div class="wrapper wrapper-contact">
    <app-nav-bar></app-nav-bar>

    <!--<div class="wrapper wrapper-contact">
        <section class="hero" id="hero">
            <div class="cover">
                <div class="container">
                    <div class="d-block">
                        <div class="row">
                            <div class="col-12">
                                <h1 style="margin-top: 40px">
                                    Acesse sua conta empresa
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>-->

    <div class="wrapper wrapper-contact">
        <section
            class="container"
            style="padding-top: 150px; padding-bottom: 150px"
        >
            <div class="row">
                <div class="col-md-12 text-center">
                    <h1>É muito bom ter você com a gente!</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-6 text-left">
                    <h3>Antes de continuar, faça seu login.</h3>
                    <div class="col-md-10">
                        <form
                            [formGroup]="accountForm"
                            (ngSubmit)="entrar()"
                            novalidate
                        >
                            <div class="row">
                                <!-- Email -->
                                <div class="form-group col-12">
                                    <label>Email</label>
                                    <input
                                        class="form-control"
                                        type="email"
                                        autocomplete="off"
                                        name="email-empresa"
                                        formControlName="email"
                                        [ngClass]="{
                                            'is-invalid':
                                                accountControls['email']
                                                    .invalid,
                                            'is-valid':
                                                accountControls['email']
                                                    .dirty &&
                                                !accountControls['email']
                                                    .invalid
                                        }"
                                        required
                                    />
                                    <div
                                        class="invalid-feedback d-block"
                                        *ngIf="accountControls['email'].invalid"
                                    >
                                        <p
                                            *ngIf="
                                                accountControls['email'].errors
                                                    .required
                                            "
                                        >
                                            O email é obrigatório
                                        </p>
                                        <p
                                            *ngIf="
                                                accountControls['email'].errors
                                                    .email
                                            "
                                        >
                                            O email não é válido
                                        </p>
                                    </div>
                                </div>

                                <!-- Password -->
                                <div class="form-group col-12">
                                    <label>Senha</label>
                                    <input
                                        class="form-control"
                                        type="password"
                                        formControlName="password"
                                        autocomplete="off"
                                        name="password-empresa"
                                        [ngClass]="{
                                            'is-invalid':
                                                accountControls['password']
                                                    .invalid,
                                            'is-valid':
                                                accountControls['password']
                                                    .dirty &&
                                                !accountControls['password']
                                                    .invalid
                                        }"
                                        required
                                    />
                                    <div
                                        class="invalid-feedback d-block"
                                        *ngIf="
                                            accountControls['password'].invalid
                                        "
                                    >
                                        <p
                                            *ngIf="
                                                accountControls['password']
                                                    .errors.required
                                            "
                                        >
                                            A senha é obrigatória
                                        </p>
                                    </div>
                                    <div>
                                        <p class="recuperar-senha">
                                            <a href="#">Esqueceu sua senha?</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <button
                                type="submit"
                                class="btn btn-block btn-lg btn-primary"
                            >
                                Entrar
                            </button>
                        </form>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row section-register">
                        <div class="col-md-12">
                            <h3>Ainda não possui cadastro conosco?</h3>
                            <p>
                                Faça já o seu cadastro e aproveite todas as
                                vantagens e benefícios
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <a
                                class="btn btn-outline-primary btn-lg btn-oval col-md-12"
                                routerLink="/registrar"
                                href="javascript:void(0)"
                            >
                                Faça seu cadastro
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>
