<section class="advantages">
    <div class="container">
        <header class="fidel-description">
            <h3>Benefícios para sua empresa</h3>
            <p>
                Clientes recorrentes são a chave para o sucesso em qualquer
                negócio, seja ele grande ou pequeno. Com a ajuda do Fidel, você
                pode acompanhar os clientes que visitam com frequência. Ele
                ajuda a analisar seus clientes leais, rastrear a frequência com
                que os visitam e prever quando farão uma nova visita.
            </p>
        </header>
        <div class="row">
            <div class="col-md-4 teste fidel-description-item">
                <img
                    src="../../../../assets/img/dots.svg"
                    style="height: 80px"
                />
                <h3>Rápido e fácil</h3>
                <p>
                    O aplicativo Fidel é rápido de configurar para qualquer
                    empresa e muito fácil de usar. Você pode configurar seu
                    aplicativo em poucas etapas e aproveitar facilmente todos os
                    benefícios.
                </p>
            </div>

            <div class="col-md-4 teste fidel-description-item">
                <img
                    src="../../../../assets/img/confiavel.svg"
                    style="height: 80px"
                />
                <h3>Confiável</h3>
                <p>
                    Agora seus clientes não precisam mais se preocupar em perder
                    seus cartões fidelidade. Nossos cartões digitais são
                    facilmente acessíveis e mais confiáveis do que os cartões de
                    papel ou plástico.
                </p>
            </div>
            <div class="col-md-4 teste fidel-description-item">
                <img
                    src="../../../../assets/img/security.svg"
                    style="height: 80px"
                />
                <h3>Seguro</h3>
                <p>
                    Os cartões fidelidade estão disponíveis diretamente no
                    smartphone dos clientes, portanto, muito menos chances de
                    serem usados por outra pessoa para obter recompensas.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 teste fidel-description-item">
                <img
                    src="../../../../assets/img/conectar.svg"
                    style="height: 80px"
                />
                <h3>Conectar</h3>
                <p>
                    Conecte-se com seus clientes por meio de nossa plataforma.
                    Seus clientes podem ficar atualizados o tempo todo por meio
                    de seus smartphones.
                </p>
            </div>

            <div class="col-md-4 teste fidel-description-item">
                <img
                    src="../../../../assets/img/analise.svg"
                    style="height: 80px"
                />
                <h3>Análise de Insight</h3>
                <p>
                    A análise de Insight é uma tecnologia usada pelas grandes
                    organizações multinacionais para analisar seus clientes.
                    Agora, a mesma tecnologia está disponível para você por meio
                    da Fidel.
                </p>
            </div>
            <div class="col-md-4 teste fidel-description-item">
                <img
                    src="../../../../assets/img/cartao_fidelidade.svg"
                    style="height: 80px"
                />
                <h3>Customizável</h3>

                <p>
                    Com o Fidel você customiza a quantidade de pontos que o
                    cliente precisa para receber seu prêmio.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="for-customer">
    <div class="container">
        <header class="fidel-description">
            <h3>Para seu cliente</h3>
            <p>
                Temos um aplicativo exclusivo para seus clientes consultarem
                seus pontos.
            </p>
        </header>
        <div class="row">
            <div class="col-md-6 description-app-customer">
                <h3>Rede social</h3>

                <p>
                    Criamos o aplicativo do cliente semelhante a uma rede
                    social, com ele seus clientes podem seguir outros usuários
                    que podem ver o estabelecimento que foi pontuado, além de
                    poder curtir a pontuação, com isso seu estabelecimento ganha
                    mais visibilidade dentro da rede.
                </p>
            </div>
            <div class="col-md-6">
                <img
                    src="../../../../assets/img/TELA1.png"
                    style="height: 400px"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <img
                    src="../../../../assets/img/TELA2.png"
                    style="height: 400px"
                />
            </div>
            <div class="col-md-6 description-app-customer">
                <h3>Carteira digital de cartões fidelidade</h3>

                <p>
                    Todos os cartões reunidos em um só lugar. É isso mesmo, não
                    importa quantos estabelecimentos seus clientes frequentam,
                    todos os cartões estarão em um só lugar funcionando como uma
                    carteira digital de cartões fidelidade.
                </p>
            </div>
        </div>
    </div>
</section>
