import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CancelationReasonsService {

    constructor(private http: HttpClient) { }

    list() {
        return this.http.get(`${environment.api.payment.url}/v1/cancelation-reasons`);
    }
}
