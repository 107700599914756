export class Company {
    nome: string;
    email: string;
    senha: string;
    telefone: string;
    cep: string;
    bairro: string;
    cidade: string;
    endereco: string;
    numero: string;
    uf: string;
    latitude: string;
    longitude: string;
    nomeReponsavel: string;
    cnpj: string;
    id_segmento: string;
    referencia: string;
}
