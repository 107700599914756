import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-confirmacao-cancelamento-assinatura',
    templateUrl: './confirmacao-cancelamento-assinatura.component.html',
    styleUrls: ['./confirmacao-cancelamento-assinatura.component.scss']
})
export class ConfirmacaoCancelamentoAssinaturaComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
