import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { Session } from '@models/session.model';

import { AccountsService } from '@services/accounts.service';

@Component({
    selector: 'app-entrar',
    templateUrl: './entrar.component.html',
    styleUrls: ['./entrar.component.scss']
})
export class EntrarComponent implements OnInit {

    accountForm: FormGroup;

    session: Session;

    constructor(
        private location: Location,
        private formBuilder: FormBuilder,
        private accountsService: AccountsService
    ) {
        this.session = new Session();
    }

    ngOnInit(): void {
        this.accountForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.accountsService
            .sessionSubject
            .subscribe((session: Session) => {
                if (session)
                    this.session = session;
                else
                    this.session = new Session();
            });
    }

    entrar() {
        if (this.accountForm.invalid) return;

        let account = this.accountForm.getRawValue();

        this.accountsService
            .signIn(account.email, account.password)
            .subscribe(() => {
                this.location.back();
            });
    }

    get accountControls() {
        return this.accountForm.controls;
    }
}
