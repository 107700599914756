import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirmacao-cadastro-empresa',
  templateUrl: './confirmacao-cadastro-empresa.component.html',
  styleUrls: ['./confirmacao-cadastro-empresa.component.scss']
})
export class ConfirmacaoCadastroEmpresaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
