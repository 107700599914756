// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    api: {
        enterprise: {
            //url: 'https://dev-api-v2.fidel.com.br/api/'
            url: 'https://api-v2.fidel.com.br/api/'
            //url: 'http://localhost:4000/api/',
        },
        payment: {
            url: 'https://payment.fidel.com.br/api/',
            //url: 'http://localhost:4000/api/',
            socket_url: 'http://localhost:22265'
        },
        backoffice: {
            url: 'https://api-empresa-backoffice.fidel.com.br/api'
            //url: 'https://dev-api-empresa-backoffice.fidel.com.br/api'
        },
        points: {
            url: 'http://localhost:3001/api'
        }
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
