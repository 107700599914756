<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-circus"
    [fullScreen]="true"
    ><p style="color: white">Aguarde...</p></ngx-spinner
>
<div class="wrapper wrapper-contact">
    <app-nav-bar></app-nav-bar>

    <section>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 style="margin-top: 40px">Chegou a hora de contratar</h1>
                </div>
            </div>
        </div>
    </section>
    <div class="wrapper-contact" style="margin-top: 20px; margin-bottom: 180px">
        <form
            [formGroup]="valForm"
            (submit)="assinar($event)"
            class="form-validate form-horizontal"
            novalidate=""
        >
            <section>
                <div class="container">
                    <div class="alert alert-primary" role="alert">
                        Os dados informados abaixo deveram corresponder ao
                        titular do endereço de cobrança informado no momento do
                        cadastro.
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    Informações pessoais
                                </div>
                                <div class="card-body">
                                    <div class="form-group row">
                                        <div class="col-lg-4">
                                            <label
                                                ><span style="color: red"
                                                    >*</span
                                                >Nome Completo</label
                                            >
                                            <input
                                                formControlName="subscriptionNome"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionNome'
                                                    ]
                                                "
                                                class="form-control form-control-zigz"
                                                type="text"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionNome'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionNome'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionNome'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                        <div class="col-lg-4">
                                            <label
                                                ><span style="color: red"
                                                    >*</span
                                                >Email</label
                                            >
                                            <input
                                                class="form-control form-control-zigz"
                                                formControlName="subscriptionEmail"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionEmail'
                                                    ]
                                                "
                                                type="text"
                                                maxlength="149"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionEmail'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionEmail'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionEmail'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                        <div class="col-lg-4">
                                            <label
                                                ><span style="color: red"
                                                    >*</span
                                                >Telefone</label
                                            >
                                            <input
                                                class="form-control form-control-zigz"
                                                formControlName="subscriptionCelular"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionCelular'
                                                    ]
                                                "
                                                mask="(00) 00000-0000"
                                                type="text"
                                                maxlength="149"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionCelular'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionCelular'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionCelular'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="form-group col-md-3">
                                            <label
                                                ><span style="color: red"
                                                    >*</span
                                                >CPF</label
                                            >
                                            <input
                                                formControlName="subscriptionCpf"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionCpf'
                                                    ]
                                                "
                                                class="form-control form-control-zigz"
                                                mask="000-000-000.00"
                                                type="text"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionCpf'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionCpf'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionCpf'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label> Data de nascimento</label>
                                            <input
                                                type="text"
                                                [(ngModel)]="
                                                    credentials.dataNascimento
                                                "
                                                formControlName="subscriptionDataNascimento"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionDataNascimento'
                                                    ]
                                                "
                                                class="form-control form-control-zigz"
                                                mask="00/00/0000"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionCartaoDataValidade'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionCartaoDataValidade'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionCartaoDataValidade'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--DADOS PESSOAIS-->
                            <br />
                            <!--ENDERECO-->
                            <div class="card">
                                <div class="card-header">Endereço</div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3 form-group">
                                            <label>CEP</label>
                                            <input
                                                formControlName="subscriptionCep"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionCep'
                                                    ]
                                                "
                                                (ngModelChange)="
                                                    valuechange($event)
                                                "
                                                [(ngModel)]="
                                                    credentials.endereoCep
                                                "
                                                mask="00000-000"
                                                class="form-control form-control-zigz"
                                                type="text"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionCep'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionCep'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionCep'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                        <div class="col-md-3 form-group">
                                            <label>Número</label>
                                            <input
                                                formControlName="subscriptionNumero"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionNumero'
                                                    ]
                                                "
                                                class="form-control form-control-zigz"
                                                [(ngModel)]="
                                                    credentials.enderecoNumero
                                                "
                                                type="text"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionNumero'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionNumero'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionNumero'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                        <div class="col-lg-6 form-group">
                                            <label>Logradouro</label>
                                            <input
                                                formControlName="subscriptionLogradouro"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionLogradouro'
                                                    ]
                                                "
                                                class="form-control form-control-zigz"
                                                [(ngModel)]="
                                                    credentials.enderecoLogradouro
                                                "
                                                type="text"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionLogradouro'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionLogradouro'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionLogradouro'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 form-group">
                                            <label>Estado</label>
                                            <input
                                                formControlName="subscriptionEstado"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionEstado'
                                                    ]
                                                "
                                                [(ngModel)]="
                                                    credentials.enderecoEstado
                                                "
                                                class="form-control form-control-zigz"
                                                type="text"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionEstado'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionEstado'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionEstado'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                        <div class="col-lg-4 form-group">
                                            <label>Cidade</label>
                                            <input
                                                formControlName="subscriptionCidade"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionCidade'
                                                    ]
                                                "
                                                class="form-control form-control-zigz"
                                                [(ngModel)]="
                                                    credentials.enderecoCidade
                                                "
                                                type="text"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionCidade'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionCidade'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionCidade'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                        <div class="col-lg-4 form-group">
                                            <label>Bairro</label>
                                            <input
                                                formControlName="subscriptionBairro"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionBairro'
                                                    ]
                                                "
                                                class="form-control form-control-zigz"
                                                [(ngModel)]="
                                                    credentials.enderecoBairro
                                                "
                                                type="text"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionBairro'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionBairro'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionBairro'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--ENDERECO-->
                            <br />
                        </div>
                        <div class="col-md-8">
                            <!--CARTAO DE CREDITO-->
                            <div class="card">
                                <div class="card-header">Cartão de crédito</div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label>Nº Cartão</label>
                                            <input
                                                type="text"
                                                class="form-control form-control-zigz"
                                                formControlName="subscriptionCartaoNumero"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionCartaoNumero'
                                                    ]
                                                "
                                                mask="0000 0000 0000 0000"
                                                (blur)="getBrand()"
                                                [(ngModel)]="
                                                    credentials.cartaoNumero
                                                "
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionCartaoNumero'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionCartaoNumero'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionCartaoNumero'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label
                                                >Nome impresso no cartão</label
                                            >
                                            <input
                                                type="text"
                                                [(ngModel)]="
                                                    credentials.cartaoNome
                                                "
                                                formControlName="subscriptionCartaoNome"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionCartaoNome'
                                                    ]
                                                "
                                                class="form-control form-control-zigz"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionCartaoNome'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionCartaoNome'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionCartaoNome'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label>Data Validade</label>
                                            <input
                                                type="text"
                                                [(ngModel)]="
                                                    credentials.cartaoDataValidade
                                                "
                                                formControlName="subscriptionCartaoDataValidade"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionCartaoDataValidade'
                                                    ]
                                                "
                                                class="form-control form-control-zigz"
                                                mask="00/0000"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionCartaoDataValidade'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionCartaoDataValidade'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionCartaoDataValidade'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label>Código de Segurança</label>
                                            <input
                                                [(ngModel)]="
                                                    credentials.cartaoCodigoSecret
                                                "
                                                formControlName="subscriptionCartaoSecret"
                                                maxlength="4"
                                                [formControl]="
                                                    valForm.controls[
                                                        'subscriptionCartaoSecret'
                                                    ]
                                                "
                                                type="text"
                                                class="form-control form-control-zigz"
                                            />
                                            <span
                                                class="text-danger"
                                                *ngIf="
                                                    valForm.controls[
                                                        'subscriptionCartaoSecret'
                                                    ].hasError('required') &&
                                                    (valForm.controls[
                                                        'subscriptionCartaoSecret'
                                                    ].dirty ||
                                                        valForm.controls[
                                                            'subscriptionCartaoSecret'
                                                        ].touched)
                                                "
                                                >Este campo é obrigatório</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <img
                                                src="../../../../assets/img/compra-segura.png"
                                                height="50"
                                            />
                                        </div>
                                        <div class="col-md-6">
                                            <img
                                                src="../../../../assets//img/pagseguro.png"
                                                height="50"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    style="
                                        margin-top: 30px;
                                        text-align: center !important;
                                        margin-bottom: 30px !important;
                                    "
                                >
                                    <br /><br /><br />
                                    <div class="col-md-12">
                                        <button
                                            type="submit"
                                            style="width: 90% !important"
                                            class="btn btn-success btn-lg"
                                        >
                                            CONTRATAR
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!--CARTAO DE CREDITO-->
                        </div>

                        <!--PRODUTO-->
                        <div class="col-md-4">
                            <!--CARTAO DE CREDITO-->
                            <div class="card mb-5 mb-lg-0">
                                <div class="card-body">
                                    <h5
                                        style="font-size: 19px"
                                        class="card-title text-muted text-uppercase text-center"
                                    >
                                        {{ plan.nome }}
                                    </h5>
                                    <h6 class="card-price text-center">
                                        {{
                                            plan.valorMensal
                                                | currency: "BRL":true:"1.2-2"
                                        }}<span
                                            class="period"
                                            style="font-size: 14px"
                                            >/Mês
                                        </span>
                                    </h6>
                                    <hr />
                                    <table class="table">
                                        <tbody>
                                            <tr
                                                *ngFor="
                                                    let plan_topic of plan.itens
                                                "
                                            >
                                                <td
                                                    style="
                                                        font-size: 12px;
                                                        text-align: left;
                                                    "
                                                >
                                                    {{ plan_topic.nome }}
                                                </td>
                                                <td
                                                    style="
                                                        font-size: 12px;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    {{ plan_topic.descricao }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--CARTAO DE CREDITO-->
                        </div>
                        <!--PRODUTO-->
                    </div>
                    <br />
                    <!-- <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <button
                                type="submit"
                                style="width: 100% !important"
                                class="btn btn-lg btn-primary"
                            >
                                CONTRATAR
                            </button>
                        </div>
                        <div class="col-md-4"></div>
                    </div>-->
                </div>
            </section>
        </form>
    </div>

    <app-footer></app-footer>
</div>
