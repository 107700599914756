import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AccountsService } from '../services/accounts.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private accountsService: AccountsService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            switch (err.status) {
                case 401:
                    this.accountsService.signOut();
                    Swal.fire({
                        title: 'Atenção..',
                        html: err.error.error,
                        icon: 'error'

                    });
                    break;
                case 400:
                    Swal.fire({
                        title: 'Atenção..',
                        html: err.error.error,
                        icon: 'warning'

                    });
                    break;
            }

            /*if (err.status === 401) {
                this.accountsService.signOut();
            }*/

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
