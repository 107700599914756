import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-informacoes-acesso',
    templateUrl: './informacoes-acesso.component.html',
    styleUrls: ['./informacoes-acesso.component.scss']
})
export class InformacoesAcessoComponent implements OnInit {

    @Input() form;
    valForm: FormGroup;
    hide: boolean = true;
    constructor(public fb: FormBuilder) { }

    ngOnInit(): void {
        this.form.addControl('step3', this.fb.group({
            email: ['', [Validators.required, Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")]],
            senha: [null, Validators.required],
            codigoIndicacao: [null, Validators.nullValidator],

        }));
        this.valForm = this.form.controls['step3'];
    }

    myFunction() {

        this.hide = !this.hide;
    }

}
