<div class="list-group">
    <span class="list-group-item item-fixed">
        MINHA CONTA
        <span class="pull-right" id="slide-submenu"> </span>
    </span>

    <a
        class="list-group-item"
        [routerLink]="['minha-assinatura']"
        routerLinkActive="active-link"
    >
        <i class="fa fa-credit-card"></i> Minha assinatura
    </a>
    <a
        class="list-group-item"
        [routerLink]="['alterar-forma-pagamento']"
        routerLinkActive="active-link"
    >
        <i class="fa fa-credit-card"></i> Alterar cartão de credito
    </a>
    <!--<a
        class="list-group-item"
        [routerLink]="['transacoes']"
        routerLinkActive="active-link"
    >
        <i class="fa fa-history"></i> Histórico
    </a>-->
    <!--- <a
        class="list-group-item"
        [routerLink]="['meus-dados']"
        routerLinkActive="active-link"
    >
        <i class="fa fa-user"></i> Gerencie seus Dados
    </a>-->
    <a class="list-group-item" (click)="signOut()">
        <i class="fa fa-close"></i> Sair
    </a>
</div>
