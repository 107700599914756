

export class FidelPagSeguroPhone {
    areaCode: string;
    number: string;
}

export class FidelPagSeguroAddress {
    street: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
}

export class FidelPagSeguroDocument {
    type: string;
    value: string;
}

export class FidelPagSeguroSender {
    name: string;
    email: string;
    ip: string;
    hash: string;
    phone: FidelPagSeguroPhone;
    address: FidelPagSeguroAddress;
    documents: FidelPagSeguroDocument[];
    constructor() {
        this.phone = new FidelPagSeguroPhone();
        this.address = new FidelPagSeguroAddress();
        this.documents = new Array<FidelPagSeguroDocument>();
    }
}



export class FidelPagSeguroHolder {
    name: string;
    birthDate: string;
    documents: FidelPagSeguroDocument[];
    billingAddress: FidelPagSeguroAddress;
    phone: FidelPagSeguroPhone;
    constructor() {
        this.documents = new Array<FidelPagSeguroDocument>();
        this.billingAddress = new FidelPagSeguroAddress();
        this.phone = new FidelPagSeguroPhone();
    }
}

export class FidelPagSeguroCreditCard {
    token: string;
    holder: FidelPagSeguroHolder;
    constructor() {
        this.holder = new FidelPagSeguroHolder();
    }
}

export class FidelPagSeguroPaymentMethod {
    type: string;
    creditCard: FidelPagSeguroCreditCard;
    constructor() {
        this.creditCard = new FidelPagSeguroCreditCard();
    }
}

export class FidelPagSeguroSubscription {
    plan: string;
    reference: string;
    sender: FidelPagSeguroSender;
    paymentMethod: FidelPagSeguroPaymentMethod;
    constructor() {
        this.sender = new FidelPagSeguroSender();
        this.paymentMethod = new FidelPagSeguroPaymentMethod();
    }
}



