import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fide',
  templateUrl: './fide.component.html',
  styleUrls: ['./fide.component.scss']
})
export class FideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
