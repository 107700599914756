<div class="wrapper wrapper-contact">
    <app-nav-bar></app-nav-bar>

    <div class="wrapper-content contact">
        <section style="padding-top: 80px">
            <div class="container fidel-description-work">
                <h2>TRABALHE NA FIDEL</h2>
                <p>
                    Se você é maluco por oportunidades de trabalhar com projetos
                    distintos, venha trabalhar com a gente e encarar novos
                    desafios. Quer ir além da caixa? Estamos esperando você aqui
                    na Fidel. Vem ser fora da caixa com a gente!
                </p>

                <div class="row">
                    <div class="col-md-12" style="margin-top: 100px">
                        <h4>Nenhuma vaga disponível no momento :(</h4>
                    </div>
                </div>
            </div>

            <br /><br /><br />
        </section>
    </div>
    <app-footer></app-footer>
</div>
