import {
    Component,
    AfterViewInit,
    ElementRef,
    OnDestroy,
    ViewEncapsulation,
    OnInit
} from '@angular/core';
import { DocApiServiceService } from '../../../services/doc-api.service';


declare let Redoc: any


@Component({
    selector: 'app-doc-api',
    templateUrl: './doc-api.component.html',
    styleUrls: ['./doc-api.component.scss']
})
export class DocApiComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(private element: ElementRef, public service: DocApiServiceService) { }
    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.attachDocumentationComponent()
    }


    async attachDocumentationComponent() {
        const data = await this.service.getJSON().toPromise();
        const elem = this.element.nativeElement.querySelector('.documentation-root')

        Redoc.init(data, {}, elem)
    }

    ngOnDestroy(): void {
        this.element.nativeElement.querySelector('.documentation-root').remove()
    }

}
